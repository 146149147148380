import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchTemplates, fetchTemplatesByFormat, fetchTemplatesByCategory, fetchTemplatesByCategoryAndFormat } from '../../utils/api/fetchTemplates';
import getUrl from '../../utils/imageUrl';

import Layout from '../_layouts/default';
import Wizard from '../_layouts/Wizard';
import Modal from '../_partials/Modal';
import Button from '../_partials/Button';

import Loading from '../_partials/Loading';
import { Template } from '../../types';
import { useHistory } from 'react-router-dom';

type RouteParams = { categorySlug: string; formatSlug: string };

interface TemplateListItemProps {
  template: Template;
}

const TemplateListItem: React.FC<TemplateListItemProps> = ({ template }) => {
  const { formatSlug } = useParams<RouteParams>();

  const fallBack = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2P4+f3zfwAJswPjd1qwUgAAAABJRU5ErkJggg==';
  const imgSrc = getUrl(template.illustration).width(400).url() || fallBack;

  const Uri = `/editor/${template._id}?format=${formatSlug}`;

  return (
    <li className="mr-8 min-w-max">
      <h1 className="text-lg font-medium mb-4 pl-2">
        <Link to={Uri} title={`Template: ${template.title}`}>
          {template.title}
        </Link>
      </h1>
      <div className="flex items-start min-w-max">
        <Link to={Uri} title={`Template: ${template.title}`} className="border-4 border-transparent hover:border-profile-blue">
          {imgSrc && <img alt={template.title} src={imgSrc} className="w-full shadow" />}
        </Link>
      </div>
    </li>
  );
};

const Templates: React.FC = () => {
  const { categorySlug, formatSlug } = useParams<RouteParams>();
  const [loading, setLoading] = useState<boolean>(true);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const history = useHistory();

  const getTemplates = (formatSlug: string, categorySlug: string) => {
    if (formatSlug === 'alle' && categorySlug === 'alle') return fetchTemplates('');
    if (formatSlug === 'alle') return fetchTemplatesByCategory(categorySlug);
    if (categorySlug === 'alle') return fetchTemplatesByFormat(formatSlug);
    if (formatSlug && formatSlug) return fetchTemplatesByCategoryAndFormat(categorySlug, formatSlug);
    return fetchTemplates('');
  };

  const handleRedirectEvent = () => {
    history.goBack();
  };

  // fetch all templates
  useEffect(() => {
    getTemplates(formatSlug, categorySlug)
      .then(result => {
        setTemplates(result || []);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [categorySlug, formatSlug]);

  // If no templates, trigger error modal
  useEffect(() => {
    setModalOpen(!templates.length);
  }, [templates.length]);

  if (loading) {
    return <Loading text="Leter etter maler" />;
  }

  if (modalOpen) {
    return (
      <Layout color="bg-profile-warmgray">
        <Wizard step={3}>
          <Modal open={modalOpen} setOpen={setModalOpen} showClose={false} level={'error'}>
            <h2 className="font-bold text-3xl mb-4">Å nei! Maskinfeil!</h2>
            <div className="font-medium text-lg mb-6">Vi finner ingen maler til det valgte formatet.</div>
            <p className="text-lg mb-2">Feilkode 324.</p>
            <p className="text-lg mb-6">Litt pinlig. </p>
            <div className="flex justify-center items-center">
              <Button type="secondary" onClick={handleRedirectEvent}>
                Gå tilbake
              </Button>
            </div>
            <div className="flex justify-center"></div>
          </Modal>
        </Wizard>
      </Layout>
    );
  }

  // list all templates as links
  return (
    <Layout color="bg-profile-warmgray">
      <Wizard step={3}>
        {templates.length && (
          <ul className="flex items-start w-screen overflow-x-scroll">
            {templates.map((template: Template) => (
              <TemplateListItem key={template._id} template={template} />
            ))}
            {/* Spacer */}
            <li>
              <div className="block w-40vw">&nbsp;</div>
            </li>
          </ul>
        )}
      </Wizard>
    </Layout>
  );
};
export default Templates;
