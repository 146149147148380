import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svg/nam_logo.svg';
import { ReactComponent as Robot } from '../../assets/svg/robot.svg';

import { UserContext } from '../../contexts/UserContext';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../authConfig';

interface LayoutProps {
  color?: string;
  showLogo?: boolean;
  showRobot?: boolean;
}
const Layout: React.FC<LayoutProps> = ({ children, color = 'bg-white', showLogo = true, showRobot = true }) => {
  // Not optimal; duplication of definitions. Should at leats move to global constants
  const robotFill: { [index: string]: string } = {
    'bg-profile-warmgray': '#e3e3e3',
    'bg-profile-beige': '#f9f7f3',
    'bg-profile-black': '#231f20',
    'bg-white': '#ffffff',
  };
  const { isAuthenticated, user } = useContext(UserContext);
  const msalInstance = new PublicClientApplication(msalConfig);

  const logout = async () => {
    if (user) {
      const { _id } = user;
      const currentAccount = msalInstance.getAccountByHomeId(_id);

      await msalInstance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: '/',
      });
    }
  };

  return (
    <>
      <div className={`w-screen h-screen overflow-x-hidden ${color}`}>
        {showLogo && (
          <Link to="/categories" className="fixed block top-0 left-0 m-6">
            <Logo width={59} height={74} />
          </Link>
        )}
        {showRobot && (
          <div className="fixed top-0 right-0 m-6 flex items-end">
            {isAuthenticated && (
              <button onClick={() => logout()}>
                <span className="border-b-2  border-profile-midgray hover:border-profile-blue pb-1 -mb-1">Logg ut</span>
              </button>
            )}
            <Robot fill={robotFill[color]} width={41} height={37} className="ml-2" title="v1" />
          </div>
        )}
        {children}
      </div>
    </>
  );
};

export default Layout;
