import React from 'react';

interface GridLinesProps {
  format: {
    height: number;
    gridColumns: number;
    gridRowHeight: number;
    width: number;
    gridRows: number;
    gridColumnWidth: number;
    multiplier: number;
  };
}
const GridLines: React.FC<GridLinesProps> = ({ format }) => {
  const gridLines = [];
  const gridNumbers = [];
  let xPos = 0;
  let yPos = 0;
  const padding = 4;

  // resolution sensitive grid numbers
  // const fontSize = 21 * (format.multiplier > 10 ? 2 : 1); // quick fix for 300 dpi
  const fontSize = format.multiplier > 1 ? Math.round(format.gridRowHeight / format.multiplier) * 4 : 32;

  // rows
  for (let i = 0; i < format.gridRows; i++) {
    gridNumbers.push(
      <text key={`row-${i}`} y={yPos + padding + fontSize} x={padding} fill="#c53030" stroke="none" fontSize={fontSize}>
        {i + 1}
      </text>,
    );
    yPos = yPos + format.gridRowHeight;
    gridLines.push(<line key={`rowLine-${i}`} x1="0" y1={yPos} x2={format.width * format.multiplier} y2={yPos} stroke="#c53030" strokeWidth={1} />);
  }

  // columns (start on second column since 1 is covered by rows )
  for (let i = 1; i < format.gridColumns; i++) {
    xPos = xPos + format.gridColumnWidth;
    gridNumbers.push(
      <text key={`col-${i}`} x={xPos + padding} y={padding + fontSize} fill="#c53030" stroke="none" fontSize={fontSize}>
        {i + 1}
      </text>,
    );
    gridLines.push(<line key={`colLine-${i}`} x1={xPos} y1="0" x2={xPos} y2={format.height * format.multiplier} stroke="#c53030" strokeWidth={1} />);
  }

  return (
    <g>
      {gridLines}
      {gridNumbers}
    </g>
  );
};

export default GridLines;
