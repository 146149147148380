import { format as formatDate, getHours, getMinutes } from 'date-fns';
import { nb, enGB } from 'date-fns/locale';

const formattedDate = (date: Date, format: string, locale = { locale: nb }) => {
  return format.length ? formatDate(date, format, locale) : '';
};

const hasValidTime = (date: Date) => !!getHours(date) || !!getMinutes(date);

/**
 * Two formats: 12. september 2021 and 12.09.2021
 */
const parseDate = (value: any | string, format = 'long', isPreview?: boolean) => {
  // Normalize dates because sanity returns dates as ISO-8601 formatted strings
  let startDate = value.content ? new Date(value.content) : null;
  let endDate = value.endDate ? new Date(value.endDate) : null;

  // only format and echo valid dates
  if (!startDate || !(startDate instanceof Date && !isNaN(startDate.valueOf()))) {
    return '';
  }

  let _format = '';
  let _locale = nb;
  const justStartDate = !endDate || !(endDate instanceof Date && !isNaN(endDate.valueOf())) || isPreview;

  switch (format) {
    case 'short':
      _format = justStartDate ? 'd.M.yyyy' : 'd.M';
      break;
    case 'long':
      _format = justStartDate ? 'd. MMMM yyyy' : 'd. MMMM';
      break;
    case 'no_year':
      _format = 'd. MMMM';
      break;
    case 'english':
      _locale = enGB;
      _format = justStartDate ? 'd MMM yyyy' : 'd MMM';
      break;
    default:
      break;
  }

  // just start date
  if (!endDate || !(endDate instanceof Date && !isNaN(endDate.valueOf())) || isPreview) {
    if (hasValidTime(startDate)) {
      if (!isPreview) {
        _format = _locale === enGB ? `${_format} HH.mm` : `${_format} 'kl.' HH.mm`;
      }
    }
    return formatDate(startDate, _format, { locale: _locale });
  }

  // start and end date ( 9.11–10.11 2020 or 9. november—9. november 2020 )
  const end = format === 'no_year' ? _format : `${_format} yyyy`;

  return `${formattedDate(startDate, _format, { locale: _locale })}–${formattedDate(endDate, end, { locale: _locale })}`;
};

export { parseDate };
