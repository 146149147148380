import { Switch, withStyles } from '@material-ui/core';

const baseHeight = 16;
const multiplier = 1.75;

const DMSwitch = withStyles({
  root: {
    width: baseHeight * 6,
    height: baseHeight * 3,
  },
  switchBase: {
    color: '#3784A0',
  },
  checked: {
    transform: `translateX(${baseHeight * 3}px) !important`,
  },
  thumb: {
    height: baseHeight * multiplier,
    width: baseHeight * multiplier,
    marginTop: baseHeight / -4,
    marginLeft: 0,
  },
  track: {
    opacity: 0.1,
    backgroundColor: '#3784A0',
    height: baseHeight,
    borderRadius: baseHeight,
  },
})(Switch);

export default DMSwitch;
