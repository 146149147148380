import sanityClient from './client';
import { Category } from '../../types';

const getCategories = (): Promise<Category[]> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(`*[_type == 'category' && !(_id in path("drafts.**"))]|order(order asc)|{title,slug,description,examples}`)
      .then((result: Category[]) => resolve(result))
      .catch((error: Error) => reject(error));
  });

export default getCategories;
