import React, { useState, useEffect } from 'react';

import CreateSVG from '../svg';

import fetchDocument from '../../utils/api/fetchDocument';
import fetchFormat from '../../utils/api/fetchFormat';
import fetchColors from '../../utils/api/fetchColors';
import { getColorScheme } from '../../utils/colorScheme';

import { Document, Format, Color } from '../../types';

type PreviewSvgProps = {
  documentId: string;
  formatSlug: string;
  variantId?: string;
  output?: string;
  mode?: string;
};

const PreviewSvg: React.FC<PreviewSvgProps> = ({ documentId, formatSlug, variantId, output = 'svg', mode = 'preview' }) => {
  const [document, setDocument] = useState<Document | null>(null);
  const [format, setFormat] = useState<Format | null>(null);
  const [colors, setColors] = useState<Color[] | null>(null);

  //  should we show the download button outside the editor. Initially this just equals preview mode
  const isDownloadable = mode === 'preview';

  useEffect(() => {
    fetchDocument(documentId)
      .then(document => setDocument(document))
      .catch(error => console.error(error));
  }, [documentId]);

  useEffect(() => {
    if (!document) return;

    const grid = document?.template?.grid;
    const gridArray = grid ? [grid.rows, grid.colL, grid.colP] : [];

    fetchFormat(formatSlug, gridArray)
      .then(format => setFormat(format))
      .catch(error => console.error(error));
  }, [formatSlug, document]);

  useEffect(() => {
    fetchColors()
      .then(colors => setColors(colors))
      .catch(error => console.error(error));
  }, []);

  // @todo: error handling. Feedback to user if missing required data property
  if (!document || !colors || !format || !document.colorId) return null;

  const colorScheme = getColorScheme(colors, document.colorId!);
  const { blocks } = document;

  if (output === 'svg')
    return (
      <>
        <CreateSVG debugLevel={0} blocks={blocks!} variantId={variantId} format={format} colorScheme={colorScheme} mode={mode} />
        {/* Off canvas full resolution SVG */}
        {isDownloadable && (
          <div style={{ position: 'absolute', left: '-10000px', top: '-10000px' }}>
            <CreateSVG debugLevel={0} blocks={blocks} format={format} colorScheme={colorScheme} mode="output" />
          </div>
        )}
      </>
    );
  // ready for outputting in other formats :)
  return null;
};

export default PreviewSvg;
