import sanityClient from './client';

const patchDocument = (id: string, payload: { [key: string]: any }): Promise<any> =>
  new Promise((resolve, reject) => {
    // console.log('Saving:', payload);
    sanityClient
      .patch(id)
      .set(payload)
      // ALTERNATIV måte for lister
      // .transaction()
      //   .patch(id, p => p.unset(['blocks']))
      //   .patch(id, p => p.setIfMissing({ blocks: [] }).append('blocks', payload))
      .commit()
      .then(result => resolve(result))
      .catch(error => reject(error));
  });

export default patchDocument;
