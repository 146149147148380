import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchFormats, fetchCategoryFormats } from '../../utils/api/fetchFormats';
import Layout from '../_layouts/default';
import Wizard from '../_layouts/Wizard';
import Loading from '../_partials/Loading';
import { Format } from '../../types';

type RouteParams = { categorySlug: string };
interface FormatListProps {
  heading: string;
  items: Format[];
}
interface FormatListItemProps {
  format: Format;
}

const FormatListItem: React.FC<FormatListItemProps> = ({ format }) => {
  const { categorySlug } = useParams<RouteParams>();

  // reduce size by 10% for each a-format [NB: not extremly solid stuff ]
  const sizeFactor = ['a1', 'a2', 'a3', 'a4', 'a5', 'a6'].includes(format.title.toLowerCase()) ? 1 - 0.1 * +format.title.charAt(format.title.length - 1) : 1;

  // use aspect ratio to calculate width/height for portrait/landscape formats

  // in rems (container = 14 * 12)
  const baselength = 9;
  const style =
    format.aspectRatio === 1
      ? { width: `${baselength * sizeFactor}rem`, height: `${baselength * sizeFactor}rem` }
      : format.aspectRatio > 1
      ? { width: `${(baselength / format.aspectRatio) * sizeFactor}rem`, height: `${baselength * sizeFactor}rem` }
      : { width: `${baselength * sizeFactor}rem`, height: `${baselength * format.aspectRatio * sizeFactor}rem` };

  // in percent (broken logic)
  // const style =
  //   format.aspectRatio === 1
  //     ? { width: `81.8%`, height: `${(format.height / format.width) * sizeFactor}%` }
  //     : format.aspectRatio > 1
  //     ? { width: `${sizeFactor / format.aspectRatio}%`, height: `${sizeFactor}%` }
  //     : { width: `${sizeFactor}%`, height: `${(format.height / format.width) * sizeFactor}%` };

  return (
    <li className="mr-6 mb-6">
      <Link
        to={`/categories/${categorySlug}/formats/${format.slug.current}`}
        title={`Format: ${format.title}`}
        className="flex justify-center items-center bg-profile-warmgray w-56 h-48 mb-3 border-4 border-transparent hover:border-profile-blue"
      >
        <div className="bg-white flex justify-center items-center text-center leading-4" style={style}>
          {format.title}
        </div>
      </Link>
      <p className="text-sm font-medium text-profile-darkgray">
        {format.width} x {format.height}
        {format.type === 'print' ? ' mm' : ' px'}
      </p>
    </li>
  );
};

const FormatList: React.FC<FormatListProps> = ({ heading, items }) => {
  return (
    <section className="mb-12">
      <h2 className="text-lg font-medium mb-6">{heading}</h2>
      <ul className="flex flex-wrap items-start">
        {items.map((format: Format) => (
          <FormatListItem key={format.slug.current} format={format} />
        ))}
      </ul>
    </section>
  );
};

const Formats: React.FC = () => {
  const { categorySlug } = useParams<RouteParams>();
  const [loading, setLoading] = useState<boolean>(true);
  const [formats, setFormats] = useState<Format[]>([]);

  // fetch all formats
  useEffect(() => {
    const getFormats = categorySlug === 'alle' ? fetchFormats : fetchCategoryFormats;
    getFormats(categorySlug)
      .then(result => {
        setFormats(result || []);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [categorySlug]);

  if (loading) {
    return <Loading text="Finner formater" />;
  }

  if (!formats.length) {
    return (
      <Wizard step={2}>
        <p>Ingen formater tilgjengelig i denne kategorien</p>
      </Wizard>
    );
  }

  // group formats by type (digital or print)
  const groupedFormats = formats.reduce((groups, format) => {
    groups[format.type] = groups[format.type] || [];
    groups[format.type].push(format as Format);
    return groups;
  }, Object.create({ digital: [], print: [] }));

  // list all formats as links
  return (
    <Layout>
      <Wizard step={2}>
        {!!groupedFormats.digital.length && <FormatList heading="Digitale formater" items={groupedFormats.digital} />}
        {!!groupedFormats.print.length && <FormatList heading="Print formater" items={groupedFormats.print} />}
      </Wizard>
    </Layout>
  );
};

export default Formats;
