import React, { Dispatch, SetStateAction } from 'react';
import { Dialog } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { ReactComponent as IconClose } from '../../assets/svg/icon_close.svg';
import { ReactComponent as Robot } from '../../assets/svg/robot.svg';
import { ReactComponent as DeadRobot } from '../../assets/svg/robot_dead.svg';
import { ReactComponent as WhoopsieRobot } from '../../assets/svg/robot_oops1.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: '#F5CE45',
      borderRadius: 0,
    },
  }),
);

interface ModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  showClose?: boolean;
  level?: string; // todo: should be enum
}

// @todo: rename component to Modal
const ExportDialogue: React.FC<ModalProps> = ({ children, open, setOpen, showClose = true, level = 'info' }) => {
  const classes = useStyles();

  const robots: any = {
    info: Robot,
    warning: WhoopsieRobot,
    error: DeadRobot,
  };
  const Avatar = robots[level];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog classes={classes} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      {showClose && (
        <button onClick={handleClose} className="absolute top-0 right-0 mt-8 mr-6">
          <IconClose width="24px" height="24px" />
        </button>
      )}

      <Avatar className="mx-auto mt-8" width="102px" fill="#F5CE45" />
      <div className="px-12 mb-8">{children}</div>
    </Dialog>
  );
};

export default ExportDialogue;
