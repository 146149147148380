import { parseDate } from './dates';
import { FontWeight } from '../types';

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

const fontWeightOptionsMap = (needle = 'regular', asNumber = false) => {
  const map = {
    light: { string: 'Light', number: 300 },
    regular: { string: 'Regular', number: 400 },
    medium: { string: 'Medium', number: 500 },
    bold: { string: 'Bold', number: 700 },
    heavy: { string: 'Heavy', number: 900 },
  };

  return asNumber ? map[needle as FontWeight].number : map[needle as FontWeight].string;
};

const textSizeOptionsMap: any = {
  1: 'XXS', // 0.5
  2: 'XS', // 1
  3: 'S', // 2
  4: 'M', // 3
  5: 'L', // 4
  6: 'XL', // 5
  7: 'XXL', // 6
};

const getBlockWidth = (width = 1, height = 1, cellWidth = 1, cellHeight = 1, rotation = 0) => {
  if ([90, 270].includes(rotation)) {
    // console.log('Width:', height, ' x ', cellWidth, '=', height * cellWidth);
    return width * cellHeight;
  }
  return width * cellWidth;
};

const getBlockHeight = (width = 1, height = 1, cellWidth = 1, cellHeight = 1, rotation = 0) => {
  if ([90, 270].includes(rotation)) {
    // console.log('Height:', width, ' x ', cellHeight, '=', width * cellHeight);
    return height * cellWidth;
    // return width * cellHeight;
  }
  return height * cellHeight;
};

export { capitalize, fontWeightOptionsMap, textSizeOptionsMap, parseDate, getBlockWidth, getBlockHeight };
