import React, { useEffect, useState, useContext } from 'react';
import { Block, Format } from '../../types';
import generateImageUrl from '../../utils/generateImageUrl';

import { EditorContext } from '../editor/EditorContext';

interface ImageProps {
  format: Format;
  block: Block;
  color: string;
  variantId?: string;
  mode?: string;
  background: string;
}

const getDimensions = (block: Block, format: Format) => {
  return { width: block.coordinates.width * format.gridColumnWidth, height: block.coordinates.height * format.gridRowHeight };
};

const getSource = (value: Block['value']) => {
  return fetch(value?.imageUrl!).then(response => response.text());
};

/**
 * Parses the {svg} string and manipulates the
 * nodes with correct {width}, {height} and {color}
 * before serialzing and return a new string
 */
const formatSVG = (svg: string, width: number, height: number, color: string) => {
  const p = new DOMParser();
  const s = new XMLSerializer();

  const doc = p.parseFromString(svg, 'image/svg+xml');
  const elm = doc.documentElement;

  elm.setAttribute('width', `${width}`);
  elm.setAttribute('height', `${height}`);
  for (var i = 0, j = elm.children.length; i < j; i++) {
    elm.children[i].setAttribute('fill', color);
  }

  return s.serializeToString(elm);
};

const RenderIcon: React.FC<ImageProps> = ({ format, block, color, background, mode }) => {
  const { width, height } = getDimensions(block, format);

  const [svg, setSvg] = useState<string | null>(null);

  useEffect(() => {
    if (!block.value) return;

    const fetchSvg = async () => {
      const source = await getSource(block.value);
      const svg = formatSVG(source, width, height, color);

      setSvg(svg);
    };

    fetchSvg();
  }, [block.value, width, height, color]);

  if (!block.value || !svg) return null;

  return <g color={color} width={width} height={height} dangerouslySetInnerHTML={{ __html: svg }} />;
};

const RenderImage: React.FC<ImageProps> = ({ format, block, color, background, mode, variantId }) => {
  const { variant } = useContext(EditorContext);

  const { width, height } = block.coordinates;

  const blockWidth = width * format.gridColumnWidth;
  const blockHeight = height * format.gridRowHeight;
  const fontSize = format.multiplier > 1 ? `${format.gridColumnWidth / format.multiplier}rem` : '15rem';

  if (mode === 'editor' && !block.value)
    return (
      <g>
        <rect width={blockWidth} height={blockHeight} className="text-profile-disabledgray fill-current">
          {' '}
        </rect>
        <text x={blockWidth / 2} y={blockHeight / 2} dominantBaseline="middle" textAnchor="middle" fontSize={fontSize} fill="#f9f7f3">
          {block.title}
        </text>
      </g>
    );

  return (
    <g>
      <image
        width={blockWidth}
        height={blockHeight}
        xlinkHref={generateImageUrl({
          value: block.value,
          fakeExtension: true,
          variantId: variantId || variant?._id,
        })}
      />
    </g>
  );
};

const Image: React.FC<ImageProps> = ({ format, block, color, background, mode, variantId }) => {
  switch (block.value?.type) {
    case 'icon':
      return <RenderIcon format={format} block={block} color={color} background={background} />;

    default:
    case 'image':
      return <RenderImage format={format} block={block} color={color} background={background} mode={mode} variantId={variantId} />;
  }
};

export default Image;
