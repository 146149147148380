// @todo: rename to FormatsToolbar or SecondaryToolbar
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { useEffectOnce } from 'react-use';

import { Variant, Block, Format } from '../../../types';
import { EditorContext } from '../EditorContext';

import Button from '../../_partials/Button';
import Switch from '../../_partials/Switch';

import { ReactComponent as PortraitIcon } from '../../../assets/svg/icon_portrait.svg';
import { ReactComponent as LandscapeIcon } from '../../../assets/svg/icon_landscape.svg';
import { ReactComponent as SquareIcon } from '../../../assets/svg/icon_square.svg';
import { ReactComponent as LayoutIcon1 } from '../../../assets/svg/icon_layout1.svg';
import { ReactComponent as LayoutIcon2 } from '../../../assets/svg/icon_layout2.svg';
import { ReactComponent as LayoutIcon3 } from '../../../assets/svg/icon_layout3.svg';

interface DebugBlockProps {
  block: Block;
}

const DebugBlock: React.FC<DebugBlockProps> = ({ block }) => {
  if (!block.coordinates) return null;

  const { title, anchor, coordinates } = block;

  return (
    <li className="mb-4">
      <h3 className="font-bold">{title}</h3>
      <p className="flex">
        <span className="pr-1">Anchor:</span>
        <span>
          {anchor?.position || 'Absolute'} / {anchor?.vertical || 'Top'} / {anchor?.horizontal || 'Left'}
        </span>
      </p>
      <p className="flex">
        <span className="pr-1">Coordinates:</span>
        <span>
          {coordinates.top} / {coordinates.left}
        </span>
      </p>
      <p className="flex">
        <span className="pr-1">Dimensions:</span>
        <span>
          {coordinates.width} x {coordinates.height}
        </span>
      </p>
    </li>
  );
};

// helpers
const aspectRatio = (f: Format) => f.height / f.width;

const isSquare = (ratio: number) => ratio === 1;
const isLandscape = (ratio: number) => ratio < 1;
const isPortrait = (ratio: number) => ratio > 1;

const getOrientation = (ratio: number) => {
  if (isSquare(ratio)) return 'square';
  if (isLandscape(ratio)) return 'landscape';
  if (isPortrait(ratio)) return 'portrait';
  return null;
};

const icons: any = {
  1: LayoutIcon1,
  2: LayoutIcon2,
  3: LayoutIcon3,
};

// Temp Inlined to parent component before cropping logic is less
// const ListVariantsOptions: React.FC<{ variants: Variant[] }> = ({ variants }) => {
//   const { variant, setActiveVariant } = useContext(EditorContext);

//   const currentVariant = variant?._id!;

//   return (
//     <div className="flex items-end -ml-3" aria-label="outlined primary button group">
//       {variants.map((variant: Variant, index) => {
//         const Icon = icons[index + (1 % 3)];
//         return (
//           <Button
//             key={variant._id}
//             type="icon"
//             icon={<Icon />}
//             className={currentVariant === variant._id ? 'text-profile-blue px-3' : 'px-3'}
//             title="variant.title"
//             onClick={() => setActiveVariant(variant._id)}
//           ></Button>
//         );
//       })}
//     </div>
//   );
// };

// check for blocks that have image uploads (triggers cropping)
const ensureFileUpload = (blocks: Block[]): boolean => blocks.some(block => ['imageBlock'].includes(block._type) && block.fileUploadOptions);

const DebugSidebar: React.FC = () => {
  const { variant, template, machineState, setActiveVariant, setActiveFormat, handleDebugLevelChange, setCropActive, setActiveTab, setProcessing } = useContext(EditorContext);
  const { variants } = template!;
  const currentVariant = variant?._id!;

  const [selectedOrientation, _setSelectedOrientation] = useState<string | null>(null);
  const [filteredVariants, setFilteredVariants] = useState<Variant[]>([]);

  // On change layout we trigger a re-crop
  // @todo: replace setTimeout with reasonable callback / useEffect / useDebounce ?
  // @todo: extract for reuse when switching formats
  // @todo: Move setter to EditorContext (like Format and Variant)
  const setSelectedOrientation = (orientation: string | null, updateImage = true) => {
    _setSelectedOrientation(orientation);

    if (hasImage && updateImage) {
      setProcessing(true);
      setActiveTab('bilde');
      setCropActive(true);
    }

    setTimeout(() => {
      setCropActive(false);
      setActiveTab('tekst');
      setProcessing(false);
    }, 1000);
  };

  // does this template have any images that are croppable?
  const hasImage = variant && ensureFileUpload(variant?.blocks);

  // Available formats
  const listFormatOptions = variant?.formats?.map((format: Format) => (
    <FormControlLabel key={format.slug.current} value={format.slug.current} control={<Radio disableRipple focusVisibleClassName="focus-visible" />} label={format.title} />
  ));

  // set correct inital orientation
  useEffectOnce(() => {
    if (!variant) return;
    setSelectedOrientation(getOrientation(aspectRatio(variant.formats[0])) || null, false);
  });

  // trigger re-cropping of image
  // useEffect(() => {
  //   if (hasImage === false) return;
  //   setCropActive(false);
  //   console.log('selectedOrientation is updated: ', selectedOrientation);
  //   console.log('selectedOrientation:', hasImage);
  //   setActiveTab('bilde');
  //   setCropActive(true);
  // }, [selectedOrientation, setActiveTab, setCropActive, hasImage]);

  // Filter list of variants by selected orientation
  useEffect(() => {
    if (!variants || !selectedOrientation) return;

    const filtered = filterVariantsByOrientation(selectedOrientation);

    if (filtered.length) {
      setFilteredVariants(filtered);
      setActiveVariant(filtered[0]._id);
    }
  }, [variants, selectedOrientation]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!variant || !variants) return null;

  const filterVariantsByOrientation = (orientation: string) => {
    switch (orientation) {
      case 'square':
        return variants.filter(({ formats }: Variant) => isSquare(aspectRatio(formats[0])));
      case 'landscape':
        return variants.filter(({ formats }: Variant) => isLandscape(aspectRatio(formats[0])));
      default:
      case 'portrait':
        return variants.filter(({ formats }: Variant) => isPortrait(aspectRatio(formats[0])));
    }
  };

  // check if orientation exists among the variants
  const orientationExits = (testRatio: (f: Format) => {}): boolean => variants.some(({ formats }) => formats.some(format => testRatio(format)));

  return (
    <div className="ml-4 mt-32">
      {machineState.debugLevel > 2 && (
        <p className="text-sm mb-12">
          <Link to={`/templates/`}>{template?.title}</Link>
        </p>
      )}
      <div className="mb-12">
        <h2 className="text-lg font-medium mb-2">Velg orientering</h2>

        <div className="flex items-end -ml-2" aria-label="outlined primary button group">
          {orientationExits((format: Format) => isLandscape(aspectRatio(format))) && (
            <Button
              type="icon"
              icon={<LandscapeIcon />}
              className={selectedOrientation === 'landscape' ? 'text-profile-blue' : ''}
              title="Liggende format (landskap)"
              onClick={() => setSelectedOrientation('landscape')}
            ></Button>
          )}
          {orientationExits((format: Format) => isPortrait(aspectRatio(format))) && (
            <Button
              type="icon"
              icon={<PortraitIcon />}
              className={selectedOrientation === 'portrait' ? 'text-profile-blue' : ''}
              title="Stående format (Portrett)"
              onClick={() => setSelectedOrientation('portrait')}
            ></Button>
          )}
          {orientationExits((format: Format) => isSquare(aspectRatio(format))) && (
            <Button
              type="icon"
              icon={<SquareIcon />}
              className={selectedOrientation === 'square' ? 'text-profile-blue' : ''}
              title="Kvadratisk format"
              onClick={() => setSelectedOrientation('square')}
            ></Button>
          )}
        </div>
      </div>
      {filteredVariants.length > 1 && (
        <div className="mb-12">
          <h2 className="text-lg font-medium mb-2">Velg layout</h2>
          <div className="flex items-end -ml-3" aria-label="outlined primary button group">
            {filteredVariants.map((variant: Variant, index) => {
              const Icon = icons[index + (1 % 3)];
              return (
                <Button
                  key={variant._id}
                  type="icon"
                  icon={<Icon />}
                  className={currentVariant === variant._id ? 'text-profile-blue px-3' : 'px-3'}
                  title="variant.title"
                  onClick={() => setActiveVariant(variant._id)}
                ></Button>
              );
            })}
          </div>

          {/* <ListVariantsOptions variants={filteredVariants} /> */}
        </div>
      )}
      {variant?.formats.length === 1 ? (
        <div className="mb-8">
          <h2 className="text-lg font-medium">Format</h2>
          <span>{variant?.formats.find(f => f.slug.current === machineState.format)?.title}</span>
        </div>
      ) : (
        <div className="mb-8">
          <h2 className="text-lg font-medium mb-2">Velg format</h2>
          <FormControl component="fieldset" fullWidth={true}>
            <RadioGroup aria-label="formats" name="formats" value={machineState.format} onChange={setActiveFormat}>
              {listFormatOptions}
            </RadioGroup>
          </FormControl>
        </div>
      )}
      <Switch focusVisibleClassName="focus-visible" disableFocusRipple disableRipple checked={machineState.debugLevel > 1} onChange={handleDebugLevelChange} name="debugLevel" />{' '}
      Vis Grid
      {machineState.debugLevel > 2 && (
        <>
          <h2 className="text-lg font-medium">Blokker</h2>
          <ul>
            {variant?.blocks?.map((block: Block) => (
              <DebugBlock key={block._key} block={block} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default DebugSidebar;
