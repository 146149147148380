import sanityClient from './client';
import { Template } from '../../types';

const fetchTemplatesByCategoryAndFormat = (category: string, format: string): Promise<Template[]> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(
        `*[_type == "variant" && !(_id in path("drafts.**"))
          && references(*[_type == "template"
            && references(*[_type == "category" && slug.current == "${category}"]._id)]._id)
          && references(*[_type == 'format' && slug.current == '${format}']._id)]{template->}`,
      )
      .then((result: { template: Template }[]) => resolve([...new Map(result.map((r: { template: Template }) => [r.template['_id'], r.template])).values()]))
      .catch((error: Error) => reject(error));
  });

// Search variants by referenced formats and return unique parent templates
const fetchTemplatesByFormat = (format: string): Promise<Template[]> => {
  // console.log(format);
  // console.log(
  //   'graphQL Query: ',
  //   `*[_type == "variant" && references(*[_type == "template"]._id)  && !(_id in path("drafts.**")) && references(*[_type == 'format' && slug.current == '${format}']._id)]{template->}`,
  // );
  return new Promise((resolve, reject) => {
    sanityClient
      .fetch(
        `*[_type == "variant" && references(*[_type == "template"]._id)  && !(_id in path("drafts.**")) && references(*[_type == 'format' && slug.current == '${format}']._id)]{template->}`,
      )
      .then((result: { template: Template }[]) => resolve([...new Map(result.map((r: { template: Template }) => [r.template['_id'], r.template])).values()]))
      .catch((error: Error) => reject(error));
  });
};

const fetchTemplatesByCategory = (category: string): Promise<Template[]> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(`*[_type == "template" && !(_id in path("drafts.**")) && references(*[_type == "category" && slug.current == '${category}']._id) ]`)
      .then((result: Template[]) => resolve(result))
      .catch((error: Error) => reject(error));
  });

const fetchTemplates = (_?: string): Promise<Template[]> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(`*[_type == "template" && !(_id in path("drafts.**"))]`)
      .then((result: Template[]) => resolve(result))
      .catch((error: Error) => reject(error));
  });

export { fetchTemplates, fetchTemplatesByFormat, fetchTemplatesByCategory, fetchTemplatesByCategoryAndFormat };
