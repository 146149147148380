import React from 'react';
import { UserProvider } from './contexts/UserContext';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DesignMachine from './components/pages/DesignMachine';

const theme = createMuiTheme({
  overrides: {
    // MuiRadio: {
    //   root: {
    //     '&:focus': {
    //       backgroundColor: 'pink',
    //     },
    //   },
    // },
    MuiSlider: {
      thumb: {
        '&:hover': {
          boxShadow: 'none',
        },
        '&.MuiSlider-active': {
          boxShadow: 'none',
        },
        '&.Mui-focusVisible': {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&.Mui-checked:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
  },
  typography: {
    fontFamily: 'MuseetSans, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
  },
  palette: {
    primary: {
      main: '#3784A0',
    },
    secondary: {
      main: '#3784A0',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <DesignMachine />
      </UserProvider>
    </ThemeProvider>
  );
};
export default App;
