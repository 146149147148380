import React, { Dispatch, SetStateAction } from 'react';
import Modal from './Modal';
import Button from './Button';

import { ReactComponent as SuccessIcon } from '../../assets/svg/icon_success.svg';

interface ShareDialogueProps {
  url: string;
  title: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ClipboardButton = ({ url, copied, setCopied }: { url: string; copied: boolean; setCopied: Dispatch<SetStateAction<boolean>> }) => {
  function handleClick() {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
    });
  }
  return <Button onClick={handleClick}>{copied ? 'Kopiert' : 'Kopier '}</Button>;
};

const ShareDialogue: React.FC<ShareDialogueProps> = ({ url, title, open, setOpen }) => {
  const [copied, setCopied] = React.useState(false);

  return (
    <Modal open={open} setOpen={setOpen}>
      <h2 className="font-bold text-3xl mb-4">Del med kollega</h2>
      <div className="font-medium text-lg mb-6">Kopier og del denne lenka.</div>
      <input type="text" value={url} className="px-3 py-2 mb-4" style={{ width: '500px' }} readOnly />
      <div className="flex justify-center items-center">
        <ClipboardButton url={url} copied={copied} setCopied={setCopied} />
        {<SuccessIcon className={`duration-500 motion-reduce:transition-none transition-opacity ml-3 ${copied ? 'opacity-100' : 'opacity-0'}`} />}
      </div>
      <div className="flex justify-center"></div>
    </Modal>
  );
};

export default ShareDialogue;
