import Canvg, { presets, RenderingContext2D } from 'canvg';
import { saveAs } from 'file-saver';
import slugify from '@sindresorhus/slugify';
import { typeConversion } from './index';

/**
 * PNG converter using canvg and OffscreenCanvas
 */
const createPNGwithCanvg = async (svg: SVGElement) => {
  const { width, height } = svg.getBoundingClientRect();
  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext('2d') as RenderingContext2D;
  const svgString = new XMLSerializer().serializeToString(svg);

  // Offscreen Canvas. NB: Note slim browsercompatibility!
  const converter = await Canvg.from(ctx, svgString, { ...presets.offscreen(), window: undefined });

  // Render only first frame, ignoring animations and mouse.
  await converter.render();

  const blob = await canvas.convertToBlob();
  const pngUrl = URL.createObjectURL(blob);
  return pngUrl;
};

interface convertProps {
  input: string | SVGElement;
  useOffscreenCanvas?: boolean;
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>;
  fileName: string;
}

/**
 * convert svg to png
 * @param {Object} options - Input to the converter
 * @param {string|SVGElement} options.input - the svg to be converted
 */
const svgToPng = ({ input, setIsExporting, fileName = 'unnamed' }: convertProps): void => {
  const svg: SVGElement = typeConversion(input);

  createPNGwithCanvg(svg)
    .then(png => {
      if (png) saveAs(png, `${slugify(fileName)}.png`); // @todo: add document name
    })
    .catch(error => console.error(error))
    .finally(() => setIsExporting(false));
};

export default svgToPng;
