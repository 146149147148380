import sanityClient from './client';

const deleteDocument = (id: string): Promise<string> =>
  new Promise((resolve, reject) => {
    sanityClient
      .delete(id)
      .then((response) => resolve(response._id))
      .catch((error: Error) => reject(error));
  });

export default deleteDocument;
