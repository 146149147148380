import React, { createContext, useState, useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import setLastVisitedTimestamp from '../utils/lastVisited'

export type User = {
  _id: string;
  name: string;
  email: string;
  _type: 'machineUser',
  homeAccountId: string,
  exp: number,
};

interface UserContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
  isAuthenticated: boolean
}

const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: () => {},
  isAuthenticated: false,
});

const UserConsumer = UserContext.Consumer;

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [lastVisited, setLastVisited] = useState(false)
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if(!lastVisited && user) {
      const { homeAccountId } = user
      if(homeAccountId) {
        setLastVisitedTimestamp(homeAccountId)
        setLastVisited(true)
      }
    }
  }, [lastVisited, user])

  return <UserContext.Provider value={{ user, setUser, isAuthenticated }}>{children}</UserContext.Provider>;
};

export { UserContext, UserConsumer, UserProvider };
