import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import slugify from '@sindresorhus/slugify';

import Button from '../../_partials/Button';
import ExportDialogue from '../../_partials/ExportDialog';
import ShareDialogue from '../../_partials/ShareDialog';

import { ReactComponent as PreviewIcon } from '../../../assets/svg/icon_expand.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/icon_download.svg';
import { ReactComponent as ShareIcon } from '../../../assets/svg/icon_share.svg';

import { EditorContext } from '../EditorContext';

const ButtonsToolbar: React.FC = () => {
  const location = useLocation();
  const [exportOpen, setExportOpen] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const { documentId, documentTitle, variant, machineState } = useContext(EditorContext);

  if (!variant) return null;

  return (
    <div className="p-4 mt-auto">
      <ExportDialogue title="Last ned" open={exportOpen} setOpen={setExportOpen} />
      <ShareDialogue title="Del" open={shareOpen} setOpen={setShareOpen} url={`${window.location.origin}/preview/${documentId}/${variant._id}/${machineState.format}`} />

      <nav className="text-right">
        <Button
          linkTo={{
            pathname: `/preview/${documentId}/${variant._id}/${machineState.format}/${slugify(documentTitle)}`,
            state: {
              from: location.pathname,
            },
          }}
          title="Forhåndsvisning"
          icon={<PreviewIcon />}
          type="secondary"
          className="mb-4"
        >
          Forhåndsvisning
        </Button>
        <Button type="secondary" onClick={() => setShareOpen(true)} icon={<ShareIcon />} className="mb-4">
          Del med kollega
        </Button>
        <Button onClick={() => setExportOpen(true)} icon={<DownloadIcon />}>
          Eksportér
        </Button>
      </nav>
    </div>
  );
};

export default ButtonsToolbar;
