import React, { useCallback, useState, useEffect, useContext, useRef } from 'react';
import sanityClient from '../../../../utils/api/client';
import { useDropzone } from 'react-dropzone';
import { useDebounce, useEffectOnce } from 'react-use';
import fetchSymbols from '../../../../utils/api/fetchSymbols';
import { CircularProgress, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Block, Symbol } from '../../../../types';
import Cropper from 'react-easy-crop';

import generateImageUrl from '../../../../utils/generateImageUrl';
import getImageSize from '../../../../utils/getImageSize';

import { EditorContext } from '../../EditorContext';

// @todo: merge
interface VariantCropState {
  _key: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

interface PreviewImageProps {
  block: Block;
  handleImageDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  cropActive: boolean;
  setCropActive: React.Dispatch<React.SetStateAction<boolean>>;
  updateBlock: (block: Block) => void;
}

const PreviewImage: React.FC<PreviewImageProps> = ({ block, handleImageDelete, cropActive, setCropActive, updateBlock }) => {
  const mounted = useRef(false);

  const { format, config, variant } = useContext(EditorContext);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [variantCrops, setVariantCrops] = useState<VariantCropState[]>(block.value?.imageCropByVariant || []);

  // clean up
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onCropComplete = useCallback(
    (_croppedArea, croppedAreaPixels) => {
      if (mounted.current) {
        setVariantCrops([...new Map([...variantCrops, { variant: variant?.title, _key: variant?._id, ...croppedAreaPixels }].map(item => [item['_key'], item])).values()]);
      }
    },
    [variant, variantCrops],
  );

  useDebounce(
    () => {
      if (!variantCrops.length) return;

      if (mounted.current) {
        updateBlock({
          ...block,
          value: {
            ...block.value!,
            imageCropByVariant: variantCrops,
          },
        });
      }
    },
    200,
    [variantCrops],
  );

  const [size, setSize] = useState<{ width: number; height: number } | null>(null);

  useEffect(() => {
    if (!block.value?.imageUrl) return;

    if (mounted.current) {
      getImageSize(
        generateImageUrl({
          value: block.value,
          fakeExtension: false,
          variantId: variant?._id,
        })!,
      ).then(result => setSize(result));
    }
  }, [block.value, variant]);

  // const handleAttributionChange = (event: ChangeEvent<HTMLInputElement>): void =>
  //   updateBlock({
  //     ...block,
  //     value: {
  //       ...block.value,
  //       content: {
  //         ...block.value?.content,
  //         attribution: event.target.value,
  //       },
  //     },
  //   });

  if (!format) return null;

  const initialCroppedAreaPixels = block.value?.imageCropByVariant?.find(o => o._key === variant?._id);
  // console.log('initialCroppedAreaPixels', initialCroppedAreaPixels);

  return (
    <>
      <div className="flex p-4">
        {!cropActive && (
          <div className="flex justify-center pr-4">
            <div className="relative">
              <button onClick={handleImageDelete} className="absolute flex top-0 right-0 w-4 h-4 m-1 rounded-full bg-black text-white">
                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="m-auto w-3 h-3">
                  <path
                    fill="currentColor"
                    d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                  />
                </svg>
              </button>
              {block.value && <img src={generateImageUrl({ value: block.value })} alt="Bilde" title="Bilde" />}
            </div>
          </div>
        )}
        <button className="h-4 flex" onClick={() => setCropActive(!cropActive)}>
          {cropActive ? (
            <>
              <svg className="h-full" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  fill="currentColor"
                  d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM288 64v96H96V64h192zm128 368c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h16v104c0 13.255 10.745 24 24 24h208c13.255 0 24-10.745 24-24V64.491a15.888 15.888 0 0 1 7.432 4.195l83.882 83.882A15.895 15.895 0 0 1 416 163.882V432zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 144c-30.879 0-56-25.121-56-56s25.121-56 56-56 56 25.121 56 56-25.121 56-56 56z"
                />
              </svg>
              <p style={{ lineHeight: 1 }}>Lagre endringer</p>
            </>
          ) : (
            <svg className="h-full" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                fill="currentColor"
                d="M493.25 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.26 18.74L12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.78-.05 2.69-.15l114.14-12.61 352.48-352.48c24.99-24.99 24.99-65.51-.01-90.5zM126.09 468.68l-93.03 10.31 10.36-93.17 263.89-263.89 82.77 82.77-263.99 263.98zm344.54-344.54l-57.93 57.93-82.77-82.77 57.93-57.93c6.04-6.04 14.08-9.37 22.63-9.37 8.55 0 16.58 3.33 22.63 9.37l37.51 37.51c12.47 12.48 12.47 32.78 0 45.26z"
              />
            </svg>
          )}
        </button>
      </div>
      {!!cropActive && (
        <div className="relative h-64 mb-4">
          <Cropper
            image={block.value?.imageUrl}
            crop={crop}
            zoom={zoom}
            aspect={(block.coordinates.width * format.gridColumnWidth) / (block.coordinates.height * format.gridRowHeight)}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            initialCroppedAreaPixels={initialCroppedAreaPixels}
          />
        </div>
      )}
      {!!size && size.width < config.imageMinWidth && <p className="p-4 pt-0 text-red-800">Bredden på bildet er for lav</p>}
      {!!size && size.height < config.imageMinHeight && <p className="p-4 pt-0 text-red-800">Høyden på bildet er for lav</p>}
      {cropActive && <div className="p-4 pt-0">Tips: Bruk musehjul/styreflate for å zoome.</div>}
      {/*
      // Deprecated. Image copyright wille be solved by text fields in the template.
      <div className="p-4 pt-0">
        <TextField className="w-full" label="Bildetekst / Foto: Navn på fotograf" value={block.value?.content?.attribution} onChange={handleAttributionChange} />
      </div> */}
    </>
  );
};

interface PreviewIconProps {
  block: Block;
  handleImageDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PreviewIcon: React.FC<PreviewIconProps> = ({ block, handleImageDelete }) => {
  return (
    <div className="p-2">
      {!block.value ? (
        <div className="flex flex-wrap">
          <div className="w-1/4 p-2 m-2 border border-dashed">
            <div className="relative" style={{ paddingTop: '100%' }}>
              <div className="absolute top-0 left-0 w-full h-full flex">
                <p className="m-auto">+</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-wrap">
            <div className="w-1/4 p-2 m-2 border relative">
              <button onClick={handleImageDelete} className="absolute flex top-0 right-0 w-4 h-4 m-1 rounded-full bg-black text-white">
                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="m-auto w-3 h-3">
                  <path
                    fill="currentColor"
                    d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                  />
                </svg>
              </button>
              <img src={block.value.imageUrl} alt="Bilde" title="Bilde" />
            </div>
          </div>
          <p className="text-xs p-2 pt-0">{block.value.content}</p>
        </>
      )}
    </div>
  );
};

interface UpdateFieldDefaultProps {
  block: Block;
  updateBlock: (block: Block) => void;
}

const UpdateImageField: React.FC<UpdateFieldDefaultProps> = ({ block, updateBlock }) => {
  const container = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);
  const [symbols, setSymbols] = useState<{ [k: string]: Symbol[] } | null>(null);
  const { cropActive, setCropActive } = useContext(EditorContext);

  useEffectOnce(() => {
    fetchSymbols().then(symbols => {
      const groupedSymbols: { [k: string]: Symbol[] } = {};

      symbols.forEach(symbol => (groupedSymbols[symbol.category] = [symbol, ...(groupedSymbols[symbol.category] || [])]));

      setSymbols(groupedSymbols);
    });
  });

  useEffect(() => {
    if (!cropActive || loading) return;

    container.current?.scrollIntoView();
  }, [cropActive, loading]);

  const handleImageChange = useCallback(
    files => {
      const file = files[0];

      setLoading(true);

      sanityClient.assets.upload('image', file).then(imageAsset => {
        setCropActive(true);

        // @todo v2: loop through all variants and generate the variantCrops array

        updateBlock({
          ...block,
          value: {
            type: 'image',
            imageUrl: imageAsset.url,
            content: {
              _type: 'image',
              asset: {
                _type: 'reference',
                _ref: imageAsset._id,
              },
            },
          },
        });

        setLoading(false);
      });
    },
    [block, updateBlock, setCropActive],
  );

  const handleSymbolChange = (symbol: Symbol) => {
    updateBlock({
      ...block,
      value: {
        type: 'icon',
        title: symbol.title,
        imageUrl: symbol.imageUrl,
      },
    });
  };

  const handleImageDelete = () =>
    updateBlock({
      ...block,
      value: undefined,
    });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleImageChange, accept: 'image/jpeg, image/png', maxFiles: 1 });

  return (
    <div className="p-4">
      <p className="text-lg mb-2">{block.title || 'Bilde'}</p>
      <div className="border border-black border-dashed bg-white" ref={container}>
        {loading ? (
          <div className="flex p-4">
            <CircularProgress className="m-auto" />
          </div>
        ) : (
          <>
            {block.value?.type === 'icon' && <PreviewIcon block={block} handleImageDelete={handleImageDelete} />}
            {block.value?.type === 'image' && (
              <PreviewImage block={block} handleImageDelete={handleImageDelete} cropActive={cropActive} setCropActive={setCropActive} updateBlock={updateBlock} />
            )}
            {!block.value?.type && (
              <>
                {block.fileUploadOptions ? (
                  <div className="p-4 flex flex-col items-center" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className="font-medium text-profile-darkgray mb-2">{isDragActive ? 'Slipp' : 'Dra og slipp'}</p>

                    <svg aria-hidden="true" focusable="false" role="img" width="98" height="64" viewBox="0 0 98 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 3.18C0 2.07876 0 1.52814 0.210911 1.10604C0.404497 0.718616 0.718616 0.404497 1.10604 0.210911C1.52814 0 2.07876 0 3.18 0H93.9371C95.0384 0 95.589 0 96.0111 0.210911C96.3985 0.404497 96.7126 0.718616 96.9062 1.10604C97.1171 1.52814 97.1171 2.07876 97.1171 3.18V60.82C97.1171 61.9212 97.1171 62.4719 96.9062 62.894C96.7126 63.2814 96.3985 63.5955 96.0111 63.7891C95.589 64 95.0384 64 93.9371 64H3.18C2.07876 64 1.52814 64 1.10604 63.7891C0.718616 63.5955 0.404497 63.2814 0.210911 62.894C0 62.4719 0 61.9212 0 60.82V3.18Z"
                        fill="#E3E3E3"
                      />
                      <path
                        d="M45.3484 26.5343C47.6591 26.5343 49.5323 24.6238 49.5323 22.2671C49.5323 19.9105 47.6591 18 45.3484 18C43.0377 18 41.1646 19.9105 41.1646 22.2671C41.1646 24.6238 43.0377 26.5343 45.3484 26.5343Z"
                        fill="#F9F7F3"
                      />
                      <path
                        d="M67.0969 44.019L56.6372 30.1508C56.2419 29.6257 55.3747 29.6257 54.9794 30.1508L46.3578 41.5818L39.8894 33.3355C39.4931 32.8292 38.6524 32.8292 38.2561 33.3355L29.8884 44.0033C29.3419 44.6989 29.8275 45.7363 30.705 45.7363H66.268C67.1351 45.7363 67.6255 44.72 67.0969 44.019Z"
                        fill="#F9F7F3"
                      />
                    </svg>

                    <p className="font-medium text-profile-darkgray mt-2 underline cursor-pointer">Eller finn bilde her</p>
                  </div>
                ) : (
                  <p className="p-4">Velg et ikon fra listen</p>
                )}
              </>
            )}
          </>
        )}
      </div>
      {block.value?.type !== 'image' && (
        <div className="border-solid border border-black bg-white mt-4">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <p>Ikoner</p>
            </AccordionSummary>
            <AccordionDetails>
              {!symbols ? (
                <div className="flex p-4">
                  <CircularProgress className="m-auto" />
                </div>
              ) : (
                <div className="flex flex-wrap p-2">
                  {Object.entries(symbols).map(([groupName, group], groupIndex) => (
                    <React.Fragment key={`symbol-${groupIndex}`}>
                      <p className="w-full m-2">{groupName}</p>
                      {group.map((symbol, symbolIndex) => (
                        <div className="w-1/4 p-1 border-3 border-transparent hover:border-profile-blue" key={`symbol-${groupIndex}-${symbolIndex}`}>
                          <img src={symbol.imageUrl} alt="Bilde" title={symbol.title} onClick={() => handleSymbolChange(symbol)} />
                        </div>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default UpdateImageField;
