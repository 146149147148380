import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import StepsIndicator from '../_partials/StepsIndicator';

type RouteParams = { categorySlug?: string; formatSlug?: string };

type WizardProps = {
  step?: number;
  children?: ReactNode;
};

const Wizard: React.FC<WizardProps> = ({ step = 1, children }) => {
  const { categorySlug, formatSlug } = useParams<RouteParams>();

  return (
    <div className="max-w-screen-lg mx-auto pt-32 md:pt-20 px-6 sm:px-16 md:px-40 md:pr-16 xl:px-4">
      <StepsIndicator activeIndex={step} category={categorySlug} format={formatSlug} />
      {children}
    </div>
  );
};

export default Wizard;
