import React, { useState, useEffect, useContext } from 'react';

import { UserContext } from '../../contexts/UserContext';
import fetchCategories from '../../utils/api/fetchCategories';
import fetchDocuments from '../../utils/api/fetchDocuments';

import LinkList from '../_partials/LinkList';
import DocumentList from '../_partials/DocumentList';

import Layout from '../_layouts/default';
import Wizard from '../_layouts/Wizard';

import Loading from '../_partials/Loading';

import { Category, Document } from '../../types';

const Categories: React.FC = () => {
  // const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<Category[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const { user } = useContext(UserContext);

  // fetch all categories and last 10 documents by user
  useEffect(() => {
    fetchCategories()
      .then(result => setCategories(result || []))
      .catch(error => console.error(error));

    if (user) {
      fetchDocuments(user._id, 10, 'title, _id, "template": template->_id, formatSlug, variantId')
        .then(result => setDocuments(result || []))
        .catch(error => console.error(error));
    }
  }, [user]);

  if (!categories && !documents) {
    return <Loading text="Laster innhold" />;
  }

  // @todo: error handling

  return (
    <Layout>
      <Wizard step={1}>
        <section className="mb-12">
          {categories.length > 0 ? (
            <LinkList
              items={categories.map(c => ({
                id: c.slug.current,
                to: `/categories/${c.slug.current}/formats`,
                title: c.title,
                details: c.description ? `/categories/${c.slug.current}` : null,
              }))}
            />
          ) : (
            <p>Ingen kategorier tilgjengelig</p>
          )}
        </section>
        {documents.length > 0 && (
          <section>
            <h1 className="text-lg font-medium mb-8">Dine siste arbeider</h1>
            <DocumentList documents={documents} />
          </section>
        )}
      </Wizard>
    </Layout>
  );
};

export default Categories;
