import svgToPdf from './exportPDF';
import svgToPng from './exportPNG';

// Util for parsing string -> element
function createSVGElement(string: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(string, 'image/svg+xml') as Document;
  return doc.firstElementChild;
}
// normalize to svg-element (@bragle: quick fix, Du vet helt sikkert den rette måten å trikse med typer på :-) )
function typeConversion(input: string | SVGElement) {
  return input === 'string' ? (createSVGElement(input) as SVGElement) : (input as SVGElement);
}

export { svgToPdf, svgToPng, typeConversion };
