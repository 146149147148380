import sanityClient from './client';
import { Format } from '../../types';
import parseFormat from '../parseFormat';

const fetchCategoryFormats = (category: string, grid?: number[]): Promise<Format[]> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(
        // get formats from all variants of templates belonging to selected category
        `*[_type == "variant" && references(*[_type == "template" && references(*[_type == "category" && slug.current == "${category}"]._id)]._id)]{
          formats[]->{
            _id,
            type,
            title,
            slug,
            width,
            height,
        }}  | order(title asc)`,
      )
      .then(result => {
        const formats = result.reduce((a: any, b: any) => a.concat(b.formats), []); // flatten
        const uniqueFormats = [...new Map<string, Format>(formats.map((f: Format) => [f['_id'], f])).values()]; // remove duplicates
        resolve(uniqueFormats ? uniqueFormats.map((format: Format) => parseFormat(format, grid)) : []);
      })
      .catch((error: Error) => reject(error));
  });

const fetchFormats = (_?: string, grid?: number[]): Promise<Format[]> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(
        `*[_type == 'format' && !(_id in path("drafts.**"))]|order(type asc)|{
          _id,
          type,
          title,
          slug,
          width,
          height,
        }  | order(title asc)`,
      )
      .then((result: Format[]) => resolve(result.map((format: Format) => parseFormat(format, grid))))
      .catch((error: Error) => reject(error));
  });

export { fetchFormats, fetchCategoryFormats };
