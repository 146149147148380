import React from 'react';
import { Block, Format, ColorScheme } from '../../types';
import DebugBox from './DebugBox';

import { getBlockWidth, getBlockHeight } from '../../utils';

// SVG elements
import Text from './Text';
import Image from './Image';
import Svg from './Svg';
import GridLines from './GridLines';

const getElement = (format: Format, block: Block, colorScheme: ColorScheme, mode: string, variantId?: string) => {
  switch (block._type) {
    case 'logoBlock':
    case 'wordMarkBlock':
      return <Svg format={format} block={block} color={colorScheme.text} />;
    case 'imageBlock':
      return <Image format={format} block={block} color={colorScheme.text} background={colorScheme.bg} variantId={variantId} mode={mode} />;
    case 'heading':
    case 'textBlock':
    case 'dateBlock':
      return <Text format={format} block={block} color={colorScheme.text} mode={mode} />;
  }
};

interface CreateElementProps {
  format: Format;
  block: Block;
  variantId?: string;
  colorScheme: ColorScheme;
  mode: string;
  debugLevel: number;
}

const CreateElement: React.FC<CreateElementProps> = ({ format, block, variantId, colorScheme, mode, debugLevel }) => {
  const { rotation, coordinates } = block;

  if (!coordinates) return null;

  const { top, left, width, height } = coordinates;
  const blockX = ((left || 0) - 1) * format.gridColumnWidth;
  const blockY = ((top || 0) - 1) * format.gridRowHeight;
  const blockWidth = getBlockWidth(width, height, format.gridColumnWidth, format.gridRowHeight, rotation);
  const blockHeight = getBlockHeight(width, height, format.gridColumnWidth, format.gridRowHeight, rotation);

  let transform = [`translate(${blockX},${blockY})`];

  /**
   *  rotate(angle[x y])
   *  The optional unitless x and y parameters specify the coordinates of the fixed point
   *       we rotate the element (and its system of coordinates) around
   *  We allways rotate anround the topleft corner [0,0]
   *  and then re-position within the new rotated internal co-ordination system.
   *
   */
  if (rotation) {
    transform.push(`rotate(${rotation}, 0, 0)`);
    switch (rotation) {
      case 270:
        transform.push(`translate(-${blockWidth},0)`);
        break;
      case 180:
        transform.push(`translate(-${blockWidth},-${blockHeight})`);
        break;
      default:
      case 90:
        transform.push(`translate(0,-${blockHeight})`);
        break;
    }
  }

  return (
    <g transform={transform.join(' ')}>
      {getElement(format, block, colorScheme, mode, variantId)}
      {debugLevel > 1 && <DebugBox color="red" strokeStyle="dashed" h={blockHeight} w={blockWidth} />}
    </g>
  );
};

const getSize = (mode: string, format: Format) => {
  // sizing attribute for the entire svg
  switch (mode) {
    case 'preview':
      return { className: 'm-10' };
    case 'output':
      return { width: format.width * format.multiplier, height: format.height * format.multiplier };
    case 'list':
      return format.width > format.height ? { width: '60%' } : { height: '75%' };
    default:
    case 'editor':
      return format.width < format.height ? { height: '75vh' } : format.width === format.height ? { width: '70vmin' } : { width: '47vw' };
  }
};

interface CreateSVGProps {
  debugLevel: number;
  blocks: Block[] | undefined;
  colorScheme: ColorScheme;
  format: Format | null;
  variantId?: string;
  mode?: string;
}

/**
 * @todo! Try using symbol def to use same svg in different viewports!
 *  => Damn, creates a typescript error I cant spend time debugging....
 */
const CreateSVG: React.FC<CreateSVGProps> = ({ debugLevel, blocks, variantId, format, colorScheme, mode = 'editor' }) => {
  if (!format) return null;

  return (
    <svg
      id={mode === 'output' ? 'output' : 'todo_getDocIdAsProp'}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${format.width * format.multiplier} ${format.height * format.multiplier}`}
      {...getSize(mode, format)}
    >
      <rect fill={colorScheme.bg} stroke="none" x="0" y="0" width={format.width * format.multiplier} height={format.height * format.multiplier}></rect>

      {/* First all images */}
      {blocks
        ?.filter(block => block._type === 'imageBlock')
        .map(block => (
          <CreateElement key={block._key} format={format} variantId={variantId} block={block} colorScheme={colorScheme} mode={mode} debugLevel={debugLevel} />
        ))}

      {/* ... then the rest */}
      {blocks
        ?.filter(block => block._type !== 'imageBlock')
        .map(block => (
          <CreateElement key={block._key} format={format} block={block} colorScheme={colorScheme} mode={mode} debugLevel={debugLevel} />
        ))}

      {debugLevel && <GridLines format={format} />}
    </svg>
  );
};

export default CreateSVG;
