import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
    },
    container: {
      margin: 'auto',
      display: 'flex',
      'flex-direction': 'column',
      'align-items': 'center',
      '& > * + *': {
        'margin-top': '1rem',
      },
    },
  }),
);

interface LoadingProps {
  text?: string;
  inline?: boolean
}

const Loading: React.FC<LoadingProps> = ({ inline, text }) => {
  const classes = useStyles();

  return (
    <div className={inline ? '' : classes.root}>
      <div className={classes.container}>
        <CircularProgress />
        {!!text && <Typography>{text}</Typography>}
      </div>
    </div>
  );
};

export default Loading;
