import React from 'react';
import { useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';

import { loginRequest } from '../authConfig';

import Button from '../components/_partials/Button';

const handleLogin = (instance: IPublicClientApplication): void => {
  instance.loginPopup(loginRequest).catch((e: any) => {
    // todo: error handling
    console.error(e);
  });
};

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return <Button onClick={() => handleLogin(instance)}>Logg inn</Button>;
};
