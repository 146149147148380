import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconInfo } from '../../assets/svg/icon_info.svg';

type Item = {
  id: string;
  to: string;
  title: string;
  details: string | null;
};
type ItemsProp = {
  items: Item[];
};

export default function Linklist({ items }: ItemsProp) {
  const listItems = items.map(({ id, to, title, details }: Item) => {
    return (
      <li key={id} className="flex items-center text-xl md:text-3xl font-medium leading-8 mt-4 md:mt-8">
        <Link to={to} className="text-profile-black border-b-2 md:border-b-4 border-profile-midgray hover:border-profile-blue pb-2">
          {title}
        </Link>
        {!!details && (
          <Link to={details}>
            <IconInfo className="ml-2" />
          </Link>
        )}
      </li>
    );
  });

  return <ul>{listItems}</ul>;
}
