import React, { useEffect, createContext, useState, Dispatch, SetStateAction, ChangeEvent } from 'react';
// import { useEffectOnce } from 'react-use';

import getConfiguration from '../../utils/getConfiguration';
import { fetchFormats } from '../../utils/api/fetchFormats';
import { setupColors } from '../../utils/colorScheme';

import { Template, Document, Block, MachineState, Variant, Format, Color } from '../../types';
import Loading from '../_partials/Loading';

interface EditorContextProps {
  machineState: MachineState;
  documentTitle: string;
  documentId: string | null;
  template: Template | null;
  variant: Variant | null;
  config: any | null;
  format: Format | null;
  blocks: Block[];
  colors: Color[];
  setActiveVariant: (id: string) => void;
  setActiveFormat: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDebugLevelChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleColorChange: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setBlocks: Dispatch<SetStateAction<Block[]>>;
  setDocumentTitle: Dispatch<SetStateAction<string>>;

  // handles image cropping
  cropActive: boolean;
  setCropActive: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<'tekst' | 'farger' | 'bilde'>>;

  // Control loader state when re-cropping images when changes
  processing: boolean;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditorContext = createContext<EditorContextProps>({
  machineState: {
    format: '', // @todo: rename to currentFormat ? Or rename the others... ? [activeFormat, activeColor...]
    currentVariant: '',
    currentColor: '',
    debugLevel: 0,
  },
  documentTitle: '',
  documentId: null,
  template: null,
  variant: null,
  blocks: [],
  config: null,
  format: null,
  colors: [],
  setActiveVariant: () => {},
  setActiveFormat: () => {},
  handleDebugLevelChange: () => {},
  handleColorChange: () => {},
  setBlocks: () => {},
  setDocumentTitle: () => {},

  cropActive: false,
  setCropActive: () => {},

  activeTab: 'tekst',
  setActiveTab: () => {},

  processing: false,
  setProcessing: () => {},
});

const EditorConsumer = EditorContext.Consumer;

interface EditorProviderProps {
  template: Template;
  document: Document;
  blocks: Block[];
}

// Apply variant layout to document blocks
// When switching varient, we search the variant for the block
// and apply the variant layout properties while preserving content
const applyVariantToBlocks = (blocks: Block[], variant: Variant) => {
  return blocks.map(block => {
    const preserveAtr = (({ value, fontStyle, fontWeight, textSize, typeface }) => ({ value, fontStyle, fontWeight, textSize, typeface }))(block);

    // get variant block by slug or id and fallback to block if not found
    const updateAtr =
      variant.blocks.find(
        variantBlock => ((variantBlock.slug && block.slug && variantBlock.slug.current === block.slug.current) || variantBlock._key === block._key) && variantBlock,
      ) || block;

    return {
      ...updateAtr,
      ...preserveAtr,
    };
  });
};

const EditorProvider: React.FC<EditorProviderProps> = ({ children, template, document, blocks: initialBlocks = [] }) => {
  // @todo: Actualy more of an editorState than machineState... Rename?
  const [machineState, setMachineState] = useState<MachineState>({
    format: '',
    currentVariant: '',
    currentColor: '',
    debugLevel: process.env.NODE_ENV === 'development' ? 2 : 0,
  });
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const [documentId, setDocumentId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(true);
  const [blocks, setBlocks] = useState(initialBlocks);
  const [variant, setVariant] = useState<Variant | null>(null);
  const [config, setConfig] = useState<any | null>(null);
  const [format, setFormat] = useState<Format | null>(null); // @todo: Document !!!
  const [formats, setFormats] = useState<Format[] | null>(null); // @todo: Document !!!
  const [colors, setColors] = useState<Color[]>([]);

  const [cropActive, setCropActive] = useState(false);
  const [activeTab, setActiveTab] = useState<'tekst' | 'farger' | 'bilde'>('tekst');

  const updateMachineState = (state: Partial<MachineState>) => {
    setMachineState(oldMachineState => ({ ...oldMachineState, ...state }));

    if (state.format) setFormat(formats?.find(format => format.slug.current === state.format) || null);
  };

  const ensureFileUpload = (blocks: Block[]): boolean => blocks.some(block => ['imageBlock'].includes(block._type) && block.fileUploadOptions);

  const autoCropImage = (): void => {
    setProcessing(true);
    setActiveTab('bilde');
    setCropActive(true);

    setTimeout(() => {
      setCropActive(false);
      setActiveTab('tekst');
      setProcessing(false);
    }, 1000);
  };

  // setters
  const setActiveVariant = (id: string) => {
    const hasImage = variant && ensureFileUpload(variant?.blocks);
    if (hasImage) {
      autoCropImage();
    }

    if (!id || !template?.variants) return;

    // the variant we are switching to
    const newVariant = template.variants.find(v => v._id === id);
    if (!newVariant) return;

    // Updated blocks. Preserve blocks content and settings while applying position from new variant
    const updatedBlocks = applyVariantToBlocks(blocks, newVariant);

    // check if we can preserve format when switching layouts
    const newFormat = newVariant.formats.find(format => format.slug.current === machineState.format) || newVariant.formats[0];

    setBlocks(updatedBlocks);
    setVariant(newVariant);
    updateMachineState({ currentVariant: id, format: newFormat.slug.current });
  };

  const setActiveFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hasImage = variant && ensureFileUpload(variant?.blocks);
    if (hasImage) {
      autoCropImage();
    }

    updateMachineState({ format: event.target.value });
  };

  // Event handlers
  const handleDebugLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => updateMachineState({ debugLevel: event.target.checked ? 2 : 0 });
  const handleColorChange = (event: React.MouseEvent<HTMLButtonElement>) => updateMachineState({ currentColor: event.currentTarget.value });

  // setting formats ? Needs to be documented !!!
  useEffect(() => {
    const grid = template?.grid;
    const gridArray = grid ? [grid.rows, grid.colL, grid.colP] : [];
    getConfiguration().then((config: any) => setConfig(config));
    fetchFormats('', gridArray).then((formats: Format[]) => setFormats(formats));
  }, [template]);

  // set default values
  useEffect(() => {
    if (!template || !formats) return;

    let defaultVariant;

    // 1. get variant if allready set
    if (document.variantId) {
      defaultVariant = template.variants?.find(v => v._id === document.variantId);
    }

    // 2. select appropriate initial variant based on formatSlug
    if (!defaultVariant && document.formatSlug) {
      defaultVariant = template.variants?.find(variant => variant.formats.find(format => document.formatSlug === format.slug.current));
    }

    // 3. select from the top of the list
    if (!defaultVariant) {
      defaultVariant = template.variants?.find(v => v._id === document.variantId) || template.variants?.[0];
    }

    const defatulColors = setupColors(template.colors, template.shades);

    if (!defaultVariant) return;

    setColors(defatulColors);
    setVariant(defaultVariant);

    setDocumentTitle(document.title);
    setDocumentId(document._id);

    // NB: Until we have custom default template color, always force to positive
    // @todo: But: If only positiv, negative or mono available, use that
    updateMachineState({
      currentVariant: defaultVariant._id,
      format: document.formatSlug || defaultVariant.formats[0].slug.current,
      currentColor: document.colorId || `${defatulColors[0]._id}.positive`,
    });
  }, [template, formats, document.formatSlug]); // eslint-disable-line react-hooks/exhaustive-deps
  // @todo: why the eslint disable ??!?

  // set loading state
  useEffect(() => {
    if (template && config && formats && machineState) setLoading(false);
  }, [template, config, formats, machineState]);

  // DEBUG
  // useEffect(() => {
  //   console.log('cropActive', cropActive);
  // }, [cropActive]);

  if (loading) return <Loading text="Laster editor" />;

  return (
    <EditorContext.Provider
      value={{
        machineState,
        documentTitle,
        documentId,
        template,
        variant,
        config,
        format,
        colors,
        blocks,
        setActiveVariant,
        setActiveFormat,
        handleDebugLevelChange,
        handleColorChange,
        setBlocks,
        setDocumentTitle,
        cropActive,
        setCropActive,
        activeTab,
        setActiveTab,
        processing,
        setProcessing,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export { EditorContext, EditorConsumer, EditorProvider };
