import React, { Dispatch, SetStateAction } from 'react';
import Modal from './Modal';
import Button from './Button';
import { useHistory } from 'react-router-dom';

interface ExportDialogueProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ExportDialogue: React.FC<ExportDialogueProps> = ({ open, setOpen }) => {
  const history = useHistory();

  const handleRedirectEvent = () => {
    history.push('/categories');
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <h2 className="font-bold text-3xl mb-4">Gå tilbake til meny</h2>
      <div className="font-medium text-lg mb-6">Plakaten din er allerede lagret i designemaskinen. Vil du forlate editoren?</div>
      <div className="flex justify-center">
        <Button onClick={() => setOpen(false)} type="secondary" className="mr-8">
          Fortsett redigering
        </Button>
        <Button onClick={handleRedirectEvent}>Gå til meny</Button>
      </div>
    </Modal>
  );
};

export default ExportDialogue;
