import React from 'react';
// import { Typesetter, IWriteOptions, IXAlign, IYAlign } from 'typesettable';
import { TypeWriter } from '../TypeWriter';
import { Block, Format, FontWeight } from '../../types';
import { capitalize, fontWeightOptionsMap, parseDate } from '../../utils';

interface TextProps {
  format: Format;
  block: Block;
  color: string;
  mode?: string;
}

/* Get the right content to put in the text string  */
const prepContent = ({ _type, value, placeHolder, dateFormat }: Block, mode?: string) => {
  // no value
  if (!value || !('content' in value) || value.content.length === 0) {
    if (mode === 'editor' && placeHolder) {
      return String(placeHolder);
    }
    return '';
  }

  // date content
  if (_type === 'dateBlock') {
    return parseDate(value, dateFormat);
  }

  // string content
  return String(value.content);
};

/** Generates the correct css class based on family, weight and style  */
const getFontClass = (typeface: 'sans' | 'serif' = 'sans', fontWeight: FontWeight = 'regular', fontStyle: 'roman' | 'italic' = 'roman') => {
  const className = [typeface, fontWeightOptionsMap(fontWeight, true), fontStyle === 'italic' ? 'i' : ''];
  return className.join('');
};

/** Generates the font famliy name  based on family, weight and style  */
const getFontFamily = (typeface: 'sans' | 'serif' = 'sans', fontWeight: FontWeight = 'regular', fontStyle: 'roman' | 'italic' = 'roman') => {
  const fontName = ['Museet', capitalize(typeface), fontWeightOptionsMap(fontWeight), fontStyle === 'italic' ? 'Italic' : ''];
  return fontName.join('');
};

// The smallest text size (1) map to a decimal fraction (0.5)
// For the other size we substract 1
const getTextSize = (size: number): number => {
  // safeguard
  if (!size) return 4;

  switch (size) {
    case 1:
      return 0.5;
    case 6:
      return 4.6;
    default:
      return size - 1;
  }
};

// Fine tune lineheigst for each font-size
const getLineHeight = (size: number): number => {
  // safeguard
  if (!size) return 1;

  switch (size) {
    case 1:
      return 1.1;
    default:
      return 1;
  }
};

/**
 * @todo: text-anchor is a function of component anchor config
 */
const Text: React.FC<TextProps> = ({ format, block, color, mode }) => {
  const { width, height } = block.coordinates;
  const { horizontal = 'Left', vertical = 'Top' } = block.anchor || {};
  const blockWidth = width * format.gridColumnWidth;
  const blockHeight = height * format.gridRowHeight;

  const textContent = prepContent(block, mode);
  const lineHeight = getLineHeight(block.textSize!);
  const fontSize = getTextSize(block.textSize!) * format.gridRowHeight;

  const textAnchor = horizontal.toLowerCase() === 'right' ? 'end' : 'start';
  const verticalAnchor = vertical.toLowerCase() === 'bottom' ? 'end' : 'start';
  const dx = textAnchor === 'end' ? blockWidth : 0;
  const dy = verticalAnchor === 'end' ? blockHeight : 0;

  return (
    <TypeWriter
      dominantBaseline="alphabetic"
      width={blockWidth}
      dx={dx}
      dy={dy}
      height={100}
      data-x={blockWidth}
      textAnchor={textAnchor}
      verticalAnchor={verticalAnchor}
      svgWidth={blockWidth}
      svgHeight={blockHeight}
      fill={color}
      className={getFontClass(block.typeface, block.fontWeight, block.fontStyle)}
      fontFamily={getFontFamily(block.typeface, block.fontWeight, block.fontStyle)}
      fontSize={fontSize}
      lineHeight={`${lineHeight}em`}
      style={{
        fontSize: fontSize,
        color: color,
      }}
      svgStyle={{
        display: 'block',
        overflow: 'visible',
      }}
      // scaleToFit={false} // @todo: Not implemented
    >
      {textContent}
    </TypeWriter>
  );
};

export default Text;
