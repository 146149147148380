import { jsPDF, jsPDFOptions } from 'jspdf';
import slugify from '@sindresorhus/slugify';
import fontMap from '../fonts';
import { typeConversion } from './index';
import 'svg2pdf.js';

interface convertProps {
  input: string | SVGElement;
  dimensions?: {
    width: number;
    height: number;
  };
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>;
  fileName: string;
}
// config-wrapper for export. TODO: Lift to context provider if needs be or simplify if not needed
const exportOptions = ({ print = true } = {}) => {
  const mmToPT = 2.8346438836889;
  return {
    dw: (v: number) => (print ? v * mmToPT : v),
    dh: (v: number) => (print ? v * mmToPT : v),
  };
};

/**
 * convert svg to pdf
 * @param {Object} options - Input to the converter
 * @param {string|SVGElement} options.input - the svg to be converted
 * @param {object} [options.dimensions = { width: 595, height: 842 ]  - PDF document dimensions in mm
 */
const svgToPdf = ({ input, dimensions = { width: 595, height: 842 }, setIsExporting, fileName = 'unnamed' }: convertProps): void => {
  let svg: SVGElement = typeConversion(input);

  const { dw, dh } = exportOptions();

  // svgToPdf options. Set dimensions in pt
  const convertOptions = {
    width: dw(dimensions.width),
    height: dh(dimensions.height),
  };

  // jsPdf options. Ref: http://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html
  const pdfOptions = {
    orientation: dimensions.width > dimensions.height ? 'landscape' : 'portrait', // portrait  or landscape. @todo: could perhaps be a property of format?
    unit: 'pt', // NB, needs to be pt for all svg-to-pdf conversions
    format: [dw(dimensions.width), dh(dimensions.height)],
    putOnlyUsedFonts: true,
  } as jsPDFOptions;

  // init pdf document
  const pdf = new jsPDF(pdfOptions);

  // add fonts (@todo: only include those in use?)
  Object.entries(fontMap).forEach(([name, font]) => {
    pdf.addFileToVFS(`${name}.ttf`, font);
    // console.log('name');
    pdf.addFont(`${name}.ttf`, name, 'normal');
  });

  // add svg to pdf
  pdf.svg(svg, convertOptions).then(() => {
    // console.log(pdf.getFont());
    // console.log(pdf.getFontList());
    // console.log(pdf);
    pdf.save(`${slugify(fileName)}.pdf`);
    setIsExporting(false);
  });
};

export default svgToPdf;
