import { Color, ColorScheme } from '../types';

const defaultColor = [{ _id: 'default', title: 'Black', primary: '#231F20', secondary: '#ffffff' }];
const defaultShade = ['positive'];

// @todo: We could simpliy this
const setupColors = (colors = defaultColor, shades = defaultShade) =>
  colors
    .map(color =>
      shades.map(shade => ({
        ...color,
        shade,
      })),
    )
    .flat();

const getColorStyle = (color: Color) => {
  switch (color.shade || 'positive') {
    case 'negative':
      return {
        color: color.secondary,
        backgroundColor: color.primary,
      };

    case 'mono':
      return {
        color: color.primary,
        backgroundColor: '#f9f7f3', // @todo: save this beige color somewhere ? Or import from tailwind config ?
      };

    default:
      return {
        color: color.primary,
        backgroundColor: color.secondary,
      };
  }
};

// token stores color and shade in one string
const getColorScheme = (colors: Color[], token: string): ColorScheme => {
  const [colorId, shade] = token.split('.');
  const { primary, secondary } = colors.find(c => c._id === colorId) || colors[0];

  return {
    text: shade === 'negative' ? secondary : primary,
    bg: shade === 'mono' ? '#f9f7f3' : shade === 'negative' ? primary : secondary,
  };
};

export { getColorScheme, getColorStyle, setupColors };
