import React, { useContext, useRef } from 'react';

import Layout from '../_layouts/default';

import DebugSidebar from './toolbar/DebugSidebar';
import EditorToolbar from './toolbar/EditorToolbar';
import TitleToolbar from './toolbar/TitleToolbar';
// import PreviewSVG from '../previews/PreviewSvg';
import CreateSVG from '../svg';

import { EditorContext } from './EditorContext';

import patchDocument from '../../utils/api/patchDocument';
import { getColorScheme } from '../../utils/colorScheme';
import ButtonsToolbar from './toolbar/ButtonsToolbar';
import { useDebounce } from 'react-use';

const DEBOUNCE_TIMEOUT = 1500; // 1.5 s

interface EditorProps {
  documentId: string;
}

const Editor: React.FC<EditorProps> = ({ documentId }) => {
  const { format, blocks, machineState, documentTitle, setDocumentTitle, colors } = useContext(EditorContext);

  // a switch use to enable API calls. No need to save everything on initial render
  const persistData = useRef(false);

  /**
   * Set doc title to best guess main heading
   * We update if not set before ("Nytt arbeid") or if very similar
   */
  const updateDocumentTitle = (): void => {
    const currentTitle = documentTitle;
    const heading = blocks.find(b => b._type === 'heading');
    const textBlock = blocks.find(b => b._type === 'textBlock');
    const newTitle = heading ? heading.value?.content : textBlock ? textBlock.value?.content : 'Nytt arbeid';
    const dirty = currentTitle === '' || currentTitle === 'Nytt arbeid' || currentTitle.substr(0, 5) === newTitle?.substr(0, 5);
    if (newTitle && dirty) {
      setDocumentTitle(newTitle);
    }
  };

  // Setup color scheme
  const colorScheme = getColorScheme(colors, machineState.currentColor);

  // set persistData to true after the initial render so that we enable saving changes to the API
  // NB: If users report data not being saved - we should look at this again
  useDebounce(
    () => {
      if (!persistData.current) {
        persistData.current = true;
        return;
      }
    },
    DEBOUNCE_TIMEOUT + 100,
    [],
  );

  // persist document title
  useDebounce(
    () => {
      if (persistData.current) {
        patchDocument(documentId, { title: documentTitle });
      }
    },
    DEBOUNCE_TIMEOUT,
    [documentTitle, documentId],
  );

  // persist variant
  useDebounce(
    () => {
      if (persistData.current) {
        patchDocument(documentId, { variantId: machineState.currentVariant });
      }
    },
    DEBOUNCE_TIMEOUT / 4,
    [machineState.currentVariant, documentId],
  );

  // persist format
  useDebounce(
    () => {
      if (persistData.current) {
        patchDocument(documentId, { formatSlug: machineState.format });
      }
    },
    DEBOUNCE_TIMEOUT / 3,
    [machineState.format, documentId],
  );

  // persist color (this is also set on inital render )
  useDebounce(
    () => {
      patchDocument(documentId, { colorId: machineState.currentColor });
    },
    DEBOUNCE_TIMEOUT / 2,
    [machineState.currentColor, documentId],
  );

  // persist blocks
  useDebounce(
    () => {
      if (persistData.current) {
        patchDocument(documentId, { blocks: blocks });
        updateDocumentTitle();
      }
    },
    DEBOUNCE_TIMEOUT,
    [blocks, documentId],
  );

  return (
    <Layout color="bg-profile-warmgray">
      <div className="overflow-hidden w-screen h-screen">
        <div className="flex justify-between h-full">
          <div className="mt-32 mx-4 w-30vw min-w-333 max-w-400">
            <EditorToolbar />
          </div>
          <div className="flex-1 flex justify-center h-full">
            <div className="mx-auto mt-20">
              <TitleToolbar />
              <CreateSVG blocks={blocks} format={format} colorScheme={colorScheme} debugLevel={machineState.debugLevel} />
            </div>
          </div>
          <div className="w-64 overflow-y-scroll flex flex-col">
            <DebugSidebar />
            <ButtonsToolbar />
          </div>
        </div>
      </div>

      {/* Off canvas full resolution SVG */}
      <div style={{ position: 'absolute', left: '-10000px', top: '-10000px' }}>
        <CreateSVG debugLevel={0} blocks={blocks} format={format} colorScheme={colorScheme} mode="output" />
      </div>
    </Layout>
  );
};

export default Editor;
