import sanityClient from './client';
import { Template } from '../../types';

const fetchTemplate = (templateId: string): Promise<Template> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(
        `*[_type == 'template' && _id == '${templateId}'][0]{
          title,
          _id,
          'variants': *[_type == "variant" && references(^._id) && !(_id in path("drafts.**"))]{
            title,
            'blocks': blocks[],
            'formats': formats[]->{type,slug,title,height,width},
            'cmpCount': count(blocks),
            ...
          },
          'colors': colors.colors[]->{_id,title,primary,secondary},
          'shades': colors.options,
          grid
        }`,
      )
      .then((template: Template) => resolve(template))
      .catch((error: Error) => reject(error));
  });

export default fetchTemplate;
