import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteDialogue from './DeleteDialog';
import DocumentViewer from '../previews/DocumentViewer';
import { Document } from '../../types';
import { ReactComponent as Trash } from '../../assets/svg/icon_trash.svg';

interface DocumentPreviewProps {
  document: Document;
  setDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ document, setDocuments }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);

  return (
    <>
      <li className="mr-8 mb-8 group">
        <Link
          to={`editor/${document.template}/${document._id}`}
          title={document.title}
          className="flex justify-center items-center bg-profile-warmgray w-64 h-40 mb-3 border-4 border-transparent group-hover:border-profile-blue"
        >
          {/* @todo: Perhaps a bit to easy just to use 'a4' as format fallback. Should however in reality not be an isssue*/}
          <DocumentViewer documentId={document._id} formatSlug={document.formatSlug || 'a4'} variantId={document.variantId} mode="list" />
        </Link>
        <div className="flex justify-between">
          <Link
            to={`editor/${document.template}/${document._id}`}
            title={document.title}
            className="text-base font-medium border-b-2 pb-1 border-profile-midgray group-hover:border-profile-blue"
          >
            {document.title}
          </Link>
          <button onClick={() => setDeleteOpen(true)} className="ml-4">
            <Trash />
          </button>
        </div>
      </li>
      <DeleteDialogue
        open={deleteOpen}
        setOpen={setDeleteOpen}
        documentId={document._id}
        onDelete={() => setDocuments(documents => documents.filter(currentDocument => currentDocument._id !== document._id))}
      />
    </>
  );
};

interface DocumentListProps {
  documents: Document[];
}

const DocumentList: React.FC<DocumentListProps> = ({ documents: initialDocuments }) => {
  const [documents, setDocuments] = useState(initialDocuments);

  return (
    <ul className="flex items-start w-screen overflow-x-scroll">
      {documents
        .filter(d => d.template && d._id)
        .map(d => (
          <DocumentPreview key={d._id} document={d} setDocuments={setDocuments} />
        ))}
      {/* Spacer */}
      <li>
        <div className="block w-56">&nbsp;</div>
      </li>
    </ul>
  );
};

export default DocumentList;
