import sanityClient from './client';
import { Format } from '../../types';
import parseFormat from '../parseFormat';

const fetchFormat = (slug: string, grid?: number[]): Promise<Format> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(
        `*[_type == 'format' && slug.current == '${slug}'][0]{
          _id,
          type,
          title,
          slug,
          width,
          height,
        }`,
      )
      .then((format: Format) => resolve(parseFormat(format, grid)))
      .catch((error: Error) => reject(error));
  });

export default fetchFormat;
