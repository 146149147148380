import React, { Dispatch, SetStateAction } from 'react';
import Modal from './Modal';
import Button from './Button';
import deleteDocument from '../../utils/api/deleteDocument';

interface ExportDialogueProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  documentId: string;
  onDelete?: () => void;
}

const DeleteDialogue: React.FC<ExportDialogueProps> = ({ open, setOpen, documentId, onDelete }) => {
  const handleDelete = () => {
    if (!documentId) return;

    deleteDocument(documentId).then(response => {
      onDelete?.();
    });
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <h2 className="font-bold text-3xl mb-4">Slett dokument</h2>
      <div className="font-medium text-lg mb-6">Er du sikker på at du vil slette dette dokumentet?</div>
      <div className="flex justify-center">
        <Button onClick={() => setOpen(false)} type="secondary" className="mr-8">
          Behold dokument
        </Button>
        <Button onClick={handleDelete}>Slett dokument</Button>
      </div>
    </Modal>
  );
};

export default DeleteDialogue;
