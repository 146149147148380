import sanityClient from '@sanity/client';

const projectId = process.env.REACT_APP_SANITY_PROJECT_ID as string;
const dataset = process.env.REACT_APP_SANITY_PROJECT_DATASET as string;
const token = process.env.REACT_APP_SANITY_TOKEN as string;

export default sanityClient({
  projectId,
  dataset,
  token,
  useCdn: process.env.NODE_ENV === 'production', // `false` if you want to ensure fresh data
});
