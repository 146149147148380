import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import BlockContent from '@sanity/block-content-to-react';

import Layout from '../_layouts/default';

import Loading from '../_partials/Loading';
import fetchCategory from '../../utils/api/fetchCategory';
import getUrl from '../../utils/imageUrl';

import { ReactComponent as ArrowLeft } from '../../assets/svg/icon_arrow_left.svg';
import { Category as CategoryType } from '../../types';

// @todo: Move RouteParams to types-file
interface RouteParams {
  categorySlug: string;
}

const Category: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [category, setCategory] = useState<CategoryType>();
  const { categorySlug } = useParams<RouteParams>();

  // fetch category
  useEffect(() => {
    fetchCategory(categorySlug)
      .then(result => {
        setCategory(result);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [categorySlug]);

  if (loading) {
    return <Loading text="Laster kategorier" />;
  }

  if (!category) {
    // @todo: handle errors
    return null;
  }

  // @todo: Perhaps backlink should be a prop in this componenent ?
  return (
    <Layout>
      <article className="max-w-screen-lg mx-auto pt-32  px-6 sm:px-16 md:px-40 md:pr-16 xl:px-4">
        <Link to={`/categories`} className="block mb-10">
          <ArrowLeft />
        </Link>
        <h1 className="text-3xl font-medium mb-6">{category.title}</h1>
        <div className="text-lg leading-normal font-medium mb-8 max-w-screen-sm">
          <BlockContent className="mb-4" blocks={category.description} />
        </div>
        {category.examples && (
          <div className="text-lg leading-normal font-medium">
            <h2 className="mb-8">Eksempler:</h2>
            <ul className="flex flex-wrap">
              {category.examples.map(e => (
                <li className="sm:pr-6 mb-6 w-full sm:w-1/2 xl:w-1/3" key={e._key}>
                  <img className="block w-full mr-6" src={getUrl(e).width(500).url() as string} alt={`Eksempel på plakat i kategorien ${category.title}`} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </article>
    </Layout>
  );
};

export default Category;
