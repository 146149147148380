import React from 'react';

interface DebugBoxProps {
  w: number;
  h: number;
  color?: string;
  strokeStyle?: 'solid' | 'dashed';
  strokeWidth?: number;
}

const getRandomColor = () => `hsl(${Math.random() * 360}, 100%, 75%)`;

const DebugBox: React.FC<DebugBoxProps> = ({ w, h, color, strokeStyle = 'solid', strokeWidth = 7 }) => {
  return (
    <rect
      className="debug-box"
      width={w}
      height={h}
      fill="none"
      stroke={color || getRandomColor()}
      strokeWidth={strokeWidth}
      {...(strokeStyle === 'dashed' ? { strokeDasharray: 30 } : {})}
    />
  );
};

export default DebugBox;
