import React, { Dispatch, SetStateAction, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Modal from './Modal';
import Button from './Button';
import { svgToPdf, svgToPng } from '../../utils/export';
import { EditorContext } from '../editor/EditorContext';
import { CircularProgress } from '@material-ui/core';
import fetchFormat from '../../utils/api/fetchFormat';

import { Format } from '../../types';

type RouteParams = { formatSlug: string; documentTitle: string };

interface ExportDialogueProps {
  title: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ExportDialogue: React.FC<ExportDialogueProps> = ({ title, open, setOpen }) => {
  const { format, documentTitle: docTitle } = useContext(EditorContext);
  const { formatSlug, documentTitle } = useParams<RouteParams>();

  const [formatForExport, setFormatForExport] = useState<Format | null>(format);
  const [isExporting, setIsExporting] = useState(false);

  const fileName = docTitle || documentTitle || 'unnamed';

  // test if we can export to PNG
  const isOffscreenCanvasSupported = () => {
    var testElem = document.createElement('canvas');
    return typeof testElem.transferControlToOffscreen === 'function';
    // return !!(elem.getContext && elem.getContext('2d'));
  };

  // if we don't have context with format, fetch based on slug
  useEffect(() => {
    if (formatForExport) return;
    fetchFormat(formatSlug, [])
      .then(format => setFormatForExport(format))
      .catch(error => console.error(error));
  }, [formatForExport, formatSlug]);

  const generateOutput = (fileFormat = 'pdf') => {
    setIsExporting(true);
    const svg = document.getElementById('output') as unknown as SVGElement;

    if (!svg || !formatForExport) {
      setIsExporting(false);
      // @todo: errorhandling
      console.error('Can not perform export. SVG (', !!svg, ') or format  (', !!format, ')  missing');
      return;
    }

    if (fileFormat === 'png') {
      svgToPng({
        input: svg,
        setIsExporting: setIsExporting,
        fileName: fileName,
      });
    } else {
      svgToPdf({
        input: svg,
        dimensions: {
          width: formatForExport.width, // svgToPdf takes dimensions in mm
          height: formatForExport.height,
        },
        setIsExporting: setIsExporting,
        fileName: fileName,
      });
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <h2 className="font-bold text-3xl mb-4">Last ned</h2>
      <div className="font-medium text-lg mb-6">Plakaten din er allerede lagret i designemaskinen. Du kan her laste den ned som enten PDF-dokument eller PNG-bilde</div>
      <div className="flex justify-center">
        {isExporting ? (
          <div className="inline-flex px-3 py-2.5 font-medium text-lg border-2 bg-transparent text-profile-black border-profile-black ">
            <CircularProgress color="inherit" size="1.7rem" className="mr-3" /> Eksporterer
          </div>
        ) : (
          <>
            <Button onClick={() => generateOutput('pdf')} className="mr-8">
              Eksporter PDF
            </Button>
            {isOffscreenCanvasSupported() ? (
              <Button onClick={() => generateOutput('png')} type="secondary">
                Eksporter PNG
              </Button>
            ) : (
              <span className="text-sm">
                For å eksportere til PNG må
                <br /> du bruke en annen nettleser,
                <br /> som f.eks. Chrome.
              </span>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default ExportDialogue;
