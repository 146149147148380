import sanityClient from './client';
import { Document } from '../../types';

const fetchDocuments = (userId?: string, limit: number = 100, fields: string = '...'): Promise<Document[]> => {
  const filters = ['[_type == "output" && !(_id in path("drafts.**"))] '];
  const limits = `[0...${limit}]`;
  const projection = `{${fields}}`;
  const order = 'order(_createdAt desc)';

  if (userId) {
    filters.push(`[references("${userId}")]`);
  }

  const query = ['*'].concat(filters).concat([order]).concat([limits]).concat([projection]).join('|');

  return new Promise((resolve, reject) => {
    sanityClient
      .fetch(query)
      .then((result: Document[]) => resolve(result))
      .catch((error: Error) => reject(error));
  });
};

export default fetchDocuments;
