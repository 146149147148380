import sanityClient from './api/client';

const setLastVisitedTimestamp = async (homeAccountId: string | null): Promise<boolean> =>
  new Promise((resolve, reject) => {
    if (!homeAccountId) {
      resolve(false);
      return;
    }

    // Set UTC/Unix timestamp as Azure for last visited.
    sanityClient
      .patch(homeAccountId)
      .set({
        lastVisited: Math.floor((new Date()).getTime() / 1000)
      })
      .commit()
      .then(() => {
        resolve(true);
      })
      .catch((error: Error) => reject(error));
  });

export default setLastVisitedTimestamp;
