import React from 'react';
import { Block, Format } from '../../types';
import { ReactComponent as Logo } from '../../assets/svg/nam_logo.svg';
import { ReactComponent as WordMark } from '../../assets/svg/nam_wordmark.svg';
import { getBlockWidth, getBlockHeight } from '../../utils';
interface SvgProps {
  format: Format;
  block: Block;
  color: string;
}

/**
 * @todo: text-anchor is a function of component anchor config
 */
const Svg: React.FC<SvgProps> = ({ format, block, color }) => {
  const { width, height } = block.coordinates;
  // const blockWidth = width * format.gridColumnWidth;
  // const blockHeight = height * format.gridRowHeight;
  const blockWidth = getBlockWidth(width, height, format.gridColumnWidth, format.gridRowHeight, block.rotation);
  const blockHeight = getBlockHeight(width, height, format.gridColumnWidth, format.gridRowHeight, block.rotation);

  const xAlign = block?.anchor?.horizontal?.toLowerCase() === 'right' ? 'xMax' : 'xMin';
  const yAlign = block?.anchor?.vertical?.toLowerCase() === 'bottom' ? 'YMax' : 'YMin';

  const svgProps = {
    preserveAspectRatio: `${xAlign}${yAlign} meet`,
    width: blockWidth,
    height: blockHeight,
    fill: color,
    stroke: 'none',
  };

  switch (block._type) {
    case 'logoBlock':
      return <Logo {...svgProps} />;

    case 'wordMarkBlock':
      return <WordMark {...svgProps} />;

    default:
      return null;
  }
};

export default Svg;
