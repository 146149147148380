import CloseDialogue from '../../_partials/CloseDialog';
import React, { useContext, ChangeEvent } from 'react';
import { EditorContext } from '../EditorContext';
import { ReactComponent as LeftArrow } from '../../../assets/svg/icon_arrow_left.svg';
import { ReactComponent as Trash } from '../../../assets/svg/icon_trash.svg';
import DeleteDialogue from 'src/components/_partials/DeleteDialog';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

type RouteParams = { templateId: string; documentId?: string };

const TitleToolbar: React.FC = () => {
  const history = useHistory();

  const { documentId } = useParams<RouteParams>();

  const [closeOpen, setCloseOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const { documentTitle, setDocumentTitle } = useContext(EditorContext);

  if (!documentId) return null;

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>): void => setDocumentTitle(event.target.value);

  return (
    <div className="flex mb-8">
      <CloseDialogue open={closeOpen} setOpen={setCloseOpen} />
      <DeleteDialogue open={deleteOpen} setOpen={setDeleteOpen} documentId={documentId} onDelete={() => history.push('/categories')} />

      <button onClick={() => setCloseOpen(true)} className="mr-4">
        <LeftArrow />
      </button>
      <input
        className="flex-grow bg-transparent focus:bg-white focus:outline-none border-l-2 border-profile-gray pl-3 text-2xl text-profile-darkgray font-medium"
        type="text"
        value={documentTitle}
        aria-label="title"
        onChange={handleValueChange}
      />
      <button onClick={() => setDeleteOpen(true)} className="ml-4">
        <Trash />
      </button>
    </div>
  );
};

export default TitleToolbar;
