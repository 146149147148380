import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute';

// pages
import SignIn from './SignIn';
import Category from './Category';
import Categories from './Categories';
import Formats from './Formats';
import Templates from './Templates';
import Preview from './Preview';
import Editor from '../editor';

const DesignMachine: React.FC = () => {
  return (
    <Router>
      <Switch>
        <ProtectedRoute path="/categories/:categorySlug/formats/:formatSlug" component={Templates} />
        <ProtectedRoute path="/categories/:categorySlug/formats" component={Formats} />
        <ProtectedRoute path="/categories/:categorySlug" component={Category} />
        <ProtectedRoute path="/categories" component={Categories} exact />

        {/* TODO: Split this in two ? I.e. one for "new" (no docID) and one for "edit" (with docID) */}
        <ProtectedRoute path="/editor/:templateId/:documentId?" component={Editor} />

        <ProtectedRoute path="/preview/:documentId/:variantId/:formatSlug/" component={Preview} />
        <ProtectedRoute path="/preview/:documentId/:variantId/:formatSlug/:documentTitle" component={Preview} />
        <Route path="/" component={SignIn} />
      </Switch>
    </Router>
  );
};

export default DesignMachine;
