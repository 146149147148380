import React, { ChangeEvent, useRef } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails as MuiAccordionDetails, withStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Block } from '../../../../types';
import { capitalize, textSizeOptionsMap } from '../../../../utils';
import Slider from '../../../_partials/Slider';
import Switch from '../../../_partials/Switch';

interface CharachterCounterProps {
  text: string;
  maxLimit: number;
}
const CharachterCounter: React.FC<CharachterCounterProps> = ({ text, maxLimit }) => {
  const currentLength = text.length;
  const meter = (currentLength / maxLimit) * 100;
  const status = meter > 90 ? 'text-profile-red' : meter > 75 ? 'text-profile-yellow' : 'text-profile-darkgray';
  return (
    <span className={`whitespace-nowrap text-sm ${status}`}>
      {currentLength}/{maxLimit} tegn
    </span>
  );
};

const AccordionDetails = withStyles({
  root: {
    padding: '0 16px 16px',
  },
})(MuiAccordionDetails);

interface UpdateFieldDefaultProps {
  block: Block;
  updateBlock: (block: Block) => void;
}
const UpdateTextField: React.FC<UpdateFieldDefaultProps> = ({ block, updateBlock }) => {
  const defaultFontWeight = useRef((block.fontWeightOptions || []).findIndex(option => option === block.fontWeight));
  const defaultTextSize = useRef(block.textSize);
  const defaultTypeface = useRef(block.typeface === 'serif' ? 1 : 0);

  const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>): void => updateBlock({ ...block, value: { content: event.target.value } });

  const handleTextSizeChange = (event: React.ChangeEvent<{}>, value: number | number[]): void => {
    updateBlock({ ...block, textSize: value as number });
  };
  const handleTypefaceChange = (event: React.ChangeEvent<{}>, value: number | number[]): void => {
    updateBlock({ ...block, typeface: value ? 'serif' : 'sans' });
  };
  const handleFontWeightChange = (event: React.ChangeEvent<{}>, value: number | number[]): void => {
    updateBlock({ ...block, fontWeight: block.fontWeightOptions[value as number] });
  };
  const handleFontStyleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateBlock({ ...block, fontStyle: event.target.checked ? 'italic' : 'roman' });
  };

  // sort ascending [1,2,3]
  block.textSizeOptions && block.textSizeOptions.sort((a, b) => a - b);

  return (
    <div className="p-4">
      <div className="flex justify-between mb-2">
        <label className="text-lg" htmlFor={block._key}>
          {block.title || 'Tekst'}
        </label>
        {block.maxStringLength && <CharachterCounter text={block.value?.content} maxLimit={block.maxStringLength} />}
      </div>
      <div className="border-profile-black border">
        <textarea
          id={block._key}
          value={block.value?.content}
          placeholder={block.placeHolder}
          className="w-full resize-none focus:outline-none px-4 py-2 text-md text-profile-darkgray "
          maxLength={block.maxStringLength}
          rows={block._type === 'heading' ? 1 : 4}
          wrap="hard"
          onChange={handleValueChange}
        />
        {(!!block.textSizeOptions || !!block.typefaceOptions) && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>Formatér tekst</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full">
                {!!block.textSizeOptions && block.textSizeOptions.length > 1 && (
                  <div className="pt-4">
                    <p>Fontstørrelse</p>
                    <div className="px-4">
                      <Slider
                        track={false}
                        defaultValue={defaultTextSize.current}
                        step={1}
                        min={block.textSizeOptions[0]}
                        max={block.textSizeOptions[block.textSizeOptions.length - 1]}
                        marks={block.textSizeOptions.map(option => ({ value: option, label: textSizeOptionsMap[option] }))}
                        onChange={handleTextSizeChange}
                      />
                    </div>
                  </div>
                )}
                {!!block.fontWeightOptions && block.fontWeightOptions.length > 1 && (
                  <div className="pt-4 mb-6">
                    <p>Bokstavtykkelse</p>
                    <div className="px-4">
                      <Slider
                        track={false}
                        defaultValue={defaultFontWeight.current}
                        step={1}
                        max={block.fontWeightOptions.length - 1}
                        marks={block.fontWeightOptions.map((option, index) => ({ value: index, label: capitalize(option) }))}
                        onChange={handleFontWeightChange}
                      />
                    </div>
                  </div>
                )}
                {!!block.typefaceOptions && (
                  <div className="pt-4 mb-6">
                    <p>Type</p>
                    <div className="px-4" style={{ width: 90 }}>
                      <Slider
                        track={false}
                        defaultValue={defaultTypeface.current}
                        step={1}
                        max={1}
                        marks={[
                          { value: 0, label: 'Sans' },
                          { value: 1, label: 'Serif' },
                        ]}
                        onChange={handleTypefaceChange}
                      />
                    </div>
                  </div>
                )}
                {!!block.fontStyleOptions && (
                  <div className="pt-4 mb-6">
                    <p>Stil</p>
                    <Switch
                      focusVisibleClassName="focus-visible"
                      disableFocusRipple
                      disableRipple
                      edge="start"
                      checked={block.fontStyle === 'italic'}
                      onChange={handleFontStyleChange}
                      name="fontStyle"
                    />{' '}
                    Italic
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default UpdateTextField;
