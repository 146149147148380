const getImageSize = (url: string): Promise<{ width: number; height: number }> =>
  new Promise(resolve => {
    const image = new Image();

    image.onload = event => {
      const target = event.target as any;
      resolve({ width: target.naturalWidth, height: target.naturalHeight });
    };

    image.src = url;
    // console.log('url', url);
  });

export default getImageSize;
