import sanityClient from './api/client';

const getConfiguration = (): Promise<any> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(`*[_type == 'config'][0]`)
      .then((config: any) => resolve(config))
      .catch((error: Error) => reject(error));
  });

export default getConfiguration;
