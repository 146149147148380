import { User } from '../contexts/UserContext';
import sanityClient from './api/client';

const authenticate = async (name: string | null, email: string | null, homeAccountId: string | null, idTokenClaims: any): Promise<User | null> =>
  new Promise((resolve, reject) => {
    if (!homeAccountId) {
      resolve(null);
      return;
    }
    const exp = idTokenClaims?.exp;
    const userDocument = {
      _id: homeAccountId,
      _type: 'machineUser',
      name,
      email,
      homeAccountId,
      exp: exp
    };

    sanityClient.createOrReplace(userDocument).then((user: any) => {
      resolve(user);
    })
    .catch((error: Error) => reject(error));
  });

export default authenticate;
