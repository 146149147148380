import { Block } from '../types';

interface Params {
  value: Block['value'];
  fakeExtension?: boolean;
  variantId?: string;
}

const generateImageUrl = ({ value, fakeExtension, variantId }: Params): string | undefined => {
  if (!value?.imageUrl) return undefined;

  let link = value.imageUrl;

  const crop = value?.imageCropByVariant?.find(o => o._key === variantId);

  if (!crop || !crop.width || !crop.height) return link;

  return `${link}?rect=${crop.x},${crop.y},${crop.width},${crop.height}${fakeExtension ? '#.jpg' : ''}`;
};

export default generateImageUrl;
