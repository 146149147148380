import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Radio, RadioGroup, FormControl, FormLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { Block } from '../../../../types';
import { parseDate } from '../../../../utils';

interface UpdateFieldDefaultProps {
  block: Block;
  updateBlock: (block: Block) => void;
}

const UpdateDateField: React.FC<UpdateFieldDefaultProps> = ({ block, updateBlock }) => {
  const handleDateChange = (date: Date | null) => {
    updateBlock({ ...block, value: { ...block.value!, content: date } });
  };

  const handleEndDateChange = (date: Date | null) => {
    updateBlock({ ...block, value: { ...block.value!, endDate: date } });
  };

  const handledRadioDateFormatChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateBlock({ ...block, dateFormat: event.target.value });
  };

  const datePickers = [{ title: block.title, value: block.value?.content || null, changeHandler: handleDateChange }];

  if (block.options?.includeEndDate && block.value?.content) {
    datePickers.push({ title: 'Sluttdato', value: block.value?.endDate || null, changeHandler: handleEndDateChange });
  }

  const pickers = datePickers.map(({ title, value, changeHandler }) => (
    <div className="p-4 pt-0" key={title}>
      <p className="text-lg">{title || 'Dato'}</p>
      <p className="text-sm">{title === 'Sluttdato' ? 'Ved bruk av sluttdato vises ikke klokkeslett.' : 'Bruk kl 00:00 for å ikke vise tidspunkt.'}</p>
      <KeyboardDateTimePicker
        error
        className="w-full"
        style={{ border: '1px solid', padding: '0.5rem 0 0.5rem 1rem', marginTop: '1rem' }}
        InputProps={{ disableUnderline: true }}
        hideTabs
        disablePast
        variant="inline"
        key={title}
        ampm={false}
        format="dd/MM/yyyy HH:mm"
        value={value}
        onChange={changeHandler}
      />
    </div>
  ));

  const selectedDateTime = block?.value;

  const radioData = [
    {
      labelId: 'radio-short',
      value: 'Kort',
      formatValue: 'short',
    },
    {
      labelId: 'radio-long',
      value: 'Utvidet',
      formatValue: 'long',
    },
    {
      labelId: 'radio-no-year',
      value: 'Uten årstall',
      formatValue: 'no_year',
    },
    {
      labelId: 'radio-english',
      value: 'Engelsk',
      formatValue: 'english',
    },
  ];

  let hasEndDate = false;
  if (block.options?.includeEndDate) {
    hasEndDate = true;
  }

  return (
    <div className="p-4">
      <p className="text-lg mb-2">{block.title || 'Dato'}</p>
      <div className="border-profile-black border pt-4">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>{pickers}</MuiPickersUtilsProvider>
        {!!block.options?.dateFormatOptions && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${block._type}-content`} id={`${block._type}-header`}>
              <p>Rediger</p>
            </AccordionSummary>
            <AccordionDetails>
              {!!block.options.dateFormatOptions && (
                <FormControl component="fieldset" fullWidth={true}>
                  <FormLabel component="legend" id="date-legend">
                    Datoformat
                  </FormLabel>
                  <RadioGroup name="dateFormat" value={block.dateFormat} onChange={handledRadioDateFormatChange}>
                    {radioData.map((radio, idx) => {
                      const { labelId, value, formatValue } = radio;

                      return (
                        <label key={`radio-key-${idx}`} htmlFor={labelId} className="MuiFormControlLabel-root">
                          <Grid container alignItems="center" alignContent="flex-start" wrap="nowrap">
                            <Grid item>
                              <Radio id={labelId} value={formatValue} disableRipple focusVisibleClassName="focus-visible" />
                            </Grid>
                            <Grid item xs={4}>
                              {value}
                            </Grid>
                            <Grid item xs={6}>
                              {selectedDateTime && parseDate(selectedDateTime, formatValue, hasEndDate)}
                            </Grid>
                          </Grid>
                        </label>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default UpdateDateField;
