import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';

import Layout from '../_layouts/default';
import DocumentViewer from '../previews/DocumentViewer';

import Button from '../_partials/Button';
import ExportDialogue from '../_partials/ExportDialog';
import { ReactComponent as DownloadIcon } from '../../assets/svg/icon_download.svg';

import { ReactComponent as IconClose } from '../../assets/svg/icon_close.svg';

type RouteParams = { documentId: string; variantId: string; formatSlug: string; documentTitle: string };
interface stateType {
  from: { pathname: string };
}
const Preview: React.FC = () => {
  const { documentId, variantId, formatSlug } = useParams<RouteParams>();
  const location = useLocation<stateType>();
  const [exportOpen, setExportOpen] = React.useState(false);

  const isDownloadable = true;

  return (
    <Layout color="bg-profile-black" showLogo={false} showRobot={false}>
      {location.state?.from && (
        <Link to={location.state.from} title="Tilbake" className="fixed top-0 right-0 m-10">
          <IconClose fill="#ffffff" />
        </Link>
      )}

      {isDownloadable && (
        <>
          <Button type="inverted" onClick={() => setExportOpen(true)} icon={<DownloadIcon />} className="fixed bottom-0 right-0 m-10">
            Eksportér
          </Button>
          <ExportDialogue title="Last ned" open={exportOpen} setOpen={setExportOpen} />
        </>
      )}

      <div className="w-screen h-screen flex flex-col items-center justify-center">
        <DocumentViewer documentId={documentId} variantId={variantId} formatSlug={formatSlug} />
      </div>
    </Layout>
  );
};

export default Preview;
