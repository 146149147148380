import React from 'react';
import { Link } from 'react-router-dom';

interface StepProps {
  index: number;
  activeIndex: number;
  label: string;
  url: string;
}

const Step: React.FC<StepProps> = ({ label, url, index, activeIndex }) => {
  return (
    <li
      className={`flex-1 text-base lg:text-2xl text-profile-black text-center whitespace-nowrap border-t-12 pt-4 ${
        index === activeIndex ? 'border-profile-blue font-bold' : activeIndex > 2 ? 'border-profile-beige' : 'border-profile-warmgray'
      }`}
    >
      {index < activeIndex ? (
        <Link
          to={url}
          title={`${index}. ${label}`}
          className={`text-profile-black pb-1 border-b-4 ${activeIndex > 2 ? 'border-profile-beige' : 'border-profile-warmgray'} hover:border-profile-blue`}
        >
          {index}. <span className="hidden sm:inline">{label}</span>
        </Link>
      ) : (
        <>
          {index}. <span className="hidden sm:inline">{label}</span>
        </>
      )}
    </li>
  );
};

interface StepsIndicatorProps {
  activeIndex: number;
  category?: string;
  format?: string;
}

const StepsIndicator: React.FC<StepsIndicatorProps> = ({ activeIndex, category = null, format = null }) => {
  const steps = [
    {
      label: 'Hva skal du lage?',
      url: '/categories',
    },
    {
      label: 'Velg format',
      url: `/categories/${category || 'alle'}/formats`,
    },
    {
      label: 'Velg mal',
      url: `/categories/${category || 'alle'}/formats/${format || 'alle'}`,
    },
    {
      label: 'Redigér',
      url: '/',
    },
  ];

  return (
    <ol className="flex justify-evenly mb-16">
      {steps.map((step, index) => (
        <Step key={`step-${index}`} {...step} index={index + 1} activeIndex={activeIndex} />
      ))}
    </ol>
  );
};

export default StepsIndicator;
