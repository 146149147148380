import sanityClient from './client';
import { Symbol } from '../../types';

const fetchSymbols = (): Promise<Symbol[]> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(`*[_type == 'symbol']{
        title,
        "imageUrl": file.asset->url,
        "category": categories[0]->title
      }`)
      .then((symbols: Symbol[]) => resolve(symbols))
      .catch((error: Error) => reject(error));
  });

export default fetchSymbols;
