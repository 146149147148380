import { Format } from '../types';

const parseFormat = (format: Format, grid?: number[]) => {
  const [rows = 32, columnsLandscape = 18, columnsPortrait = 18] = grid || [];

  // @todo: need better error handling
  if (!format.height || !format.width) throw new Error('Malformed format');

  const aspectRatio = format.height / format.width;

  let gridRows = 0;
  let gridColumns = 0;

  if (format.width === format.height) {
    // square
    gridRows = rows;
    gridColumns = rows;
  } else if (format.width > format.height) {
    // landscape
    gridColumns = columnsLandscape;
    gridRows = rows;
  } else {
    // portrait
    gridColumns = columnsPortrait;
    gridRows = rows;
  }

  // dpi 300
  format.multiplier = format.type === 'print' ? 11.80952381 : 1;

  // dpi 72
  // format.multiplier = format.type === 'print' ? 2.833333333 : 1;

  const gridRowHeight = (format.height * format.multiplier) / gridRows;
  const gridColumnWidth = (format.width * format.multiplier) / gridColumns;

  return { ...format, aspectRatio, gridRows, gridColumns, gridRowHeight, gridColumnWidth };
};

export default parseFormat;
