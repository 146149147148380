import React, { useState, useContext, useEffect, useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import Loading from '../_partials/Loading';
import Button from './../_partials/Button';
import { ReactComponent as RightArrow } from '../../assets/svg/icon_arrow_right.svg';

import authenticate from '../../utils/authenticate';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import Layout from '../_layouts/default';

import { ReactComponent as Robot } from '../../assets/svg/robot.svg';

import { SignInButton } from '../SignInButton';

const SignIn: React.FC = () => {
  // v2: Azure AD
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const [updateSanityDocument, setUpdateSanityDocument] = useState(true);
  const [showGoToButton, setShowGoToButton] = useState(false);
  const [nameError, setNameError] = useState('');
  const { setUser } = useContext(UserContext);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    const account = accounts[0];

    if (isAuthenticated && updateSanityDocument && account) {
      const { name = null, username: email = null, homeAccountId = null, idTokenClaims = {} } = account;

      authenticate(name, email, homeAccountId, idTokenClaims).then(user => {
        if (mounted.current) {
          if (!user || !email || !homeAccountId) {
            setNameError('Ukjent bruker eller feil i brukernavnet');
            return;
          }

          setUser(user);
          setUpdateSanityDocument(false);
          setShowGoToButton(false);
        }
      });
    }

    return () => {
      mounted.current = false;
    };
  }, [updateSanityDocument, isAuthenticated, accounts, setUser]);

  useEffect(() => {
    if (!isAuthenticated) {
      setShowGoToButton(true);
    }
  }, [isAuthenticated]);

  const location = useLocation();
  const { referrer } = (location.state as { referrer?: Object }) || { referrer: { pathname: '/categories' } };

  if (isAuthenticated && referrer && !updateSanityDocument && !showGoToButton) {
    return <Redirect to={referrer} />;
  }

  return (
    <Layout color="bg-profile-beige">
      <div className="w-screen h-screen flex flex-col items-center justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-3xl mb-10 text-center">Velkommen til designmaskinen!</h1>
          <Robot className="mb-16" fill="#F9F7F3" />
          {isAuthenticated ? (
            <div>
              {showGoToButton ? (
                <Button onClick={() => setShowGoToButton(false)} icon={<RightArrow />}>
                  Kom i gang
                </Button>
              ) : (
                <Loading text="Laster bruker" inline />
              )}
            </div>
          ) : (
            <SignInButton />
          )}
          {nameError !== '' && <p>{nameError}</p>}
        </div>
      </div>
    </Layout>
  );
};

export default SignIn;
