import React, { useState, useContext } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import fetchTemplate from '../../utils/api/fetchTemplate';
import Editor from './Editor';
import { Template, Document, Block } from '../../types';
import createDocument from '../../utils/api/createDocument';
import fetchDocument from '../../utils/api/fetchDocument';
import patchDocument from '../../utils/api/patchDocument';
import { EditorProvider } from './EditorContext';
import { UserContext } from '../../contexts/UserContext';

import Loading from '../_partials/Loading';
import { useEffectOnce } from 'react-use';

const useQuery = () => new URLSearchParams(useLocation().search);

type RouteParams = { templateId: string; documentId?: string };

const Wrapper: React.FC = () => {
  const { user } = useContext(UserContext);

  const query = useQuery();

  const { templateId, documentId } = useParams<RouteParams>();
  const [template, setTemplate] = useState<Template | null>(null);
  const [document, setDocument] = useState<Document | null>(null);
  const [blocks, setBlocks] = useState<Block[] | null>(null);

  const history = useHistory();
  const { pathname } = useLocation();

  // fetch template
  useEffectOnce(() => {
    // @todo: Error handling with user feeback. We should display a message and a possible action
    if (!user) {
      console.error('Housten we have a problem, no user!');
      return;
    }

    fetchTemplate(templateId).then(template => {
      setTemplate(template);

      const templateBlocks = template.variants?.[0]?.blocks;

      // missing blocks in template, abort | @tdodo: Throw error ??!?
      if (!templateBlocks?.length) return;

      // fetch existing document if it exists
      if (documentId) {
        fetchDocument(documentId)
          .then(document => {
            setDocument(document);

            // if new blocks have been added to the template, update the document and blocks array
            if (templateBlocks && document.blocks && templateBlocks.length > document.blocks.length) {
              // Basically the same as lodash _.unionBy(). Needs "downlevelIteration" in compiler options,
              const merged = [...new Map(templateBlocks.concat(document.blocks).map((item: Block) => [item['_key'], item])).values()];

              patchDocument(documentId, { blocks: merged })
                .then(document => setBlocks(merged as Block[]))
                .catch(error => console.error('Transaction failed: ', error.message));

              // no new template blocks, just get blocks from document
            } else {
              setBlocks(document.blocks as Block[]);
            }
          })
          .catch(error => {
            console.error(error);
          });

        // Create document and setup blocks if new
      } else {
        createDocument(user._id, template._id, 'Nytt arbeid').then(document => {
          // pass along format selected in the wizard
          const selectedFormat = query.get('format') || undefined;

          setDocument({ ...document, formatSlug: selectedFormat });

          patchDocument(document._id, { blocks: templateBlocks })
            .then(result => setBlocks(templateBlocks as Block[]))
            .catch(error => console.error('Transaction failed: ', error.message));

          // Set document iD in path. Must remove trailing slash to avoid infinte looping. Alternativly build pathname manually...
          if (documentId !== document._id) history.push(`${pathname.replace(/\/$/, '')}/${document._id}`);
        });
      }
    });
  });

  // @todo: since we are passing along the entire document, maybe we don't need to extract blocks here ?
  if (!template || !document || !blocks || !documentId) return <Loading text="Laster innhold" />;

  return (
    <EditorProvider template={template} document={document} blocks={blocks}>
      <Editor documentId={documentId} />
    </EditorProvider>
  );
};

export default Wrapper;
