// @todo: rename to InputsToolBar or PrimaryToolBar
import React, { useContext } from 'react';
import { EditorContext } from '../EditorContext';

import UpdateTextField from './toolbarBlocks/TextField';
import UpdateDateField from './toolbarBlocks/DateTimeField';
import UpdateImageField from './toolbarBlocks/ImageField';
import ColorPicker from './toolbarBlocks/ColorPicker';

import Loading from '../../_partials/Loading';

import { Block } from '../../../types';

const ensureTextBlocks = (blocks: Block[]): boolean => blocks.some(block => ['heading', 'textBlock', 'dateBlock'].includes(block._type));

const createTextBlockFields = (block: Block, updateBlock: (block: Block) => void): React.ReactNode => {
  switch (block._type) {
    case 'heading':
    case 'textBlock':
      return <UpdateTextField block={block} updateBlock={updateBlock} />;
    case 'dateBlock':
      return <UpdateDateField block={block} updateBlock={updateBlock} />;
  }
};

const ensureImageBlocks = (blocks: Block[]): boolean => blocks.some(block => ['imageBlock'].includes(block._type));

const createImageBlockFields = (block: Block, updateBlock: (block: Block) => void): React.ReactNode => {
  switch (block._type) {
    case 'imageBlock':
      return <UpdateImageField block={block} updateBlock={updateBlock} />;
  }
};

const EditorToolbar: React.FC = () => {
  const { blocks, setBlocks, activeTab, setActiveTab, processing } = useContext(EditorContext);

  const updateBlock = (key: number) => (block: Block) => {
    blocks[key] = block;

    setBlocks([...blocks]);
  };

  return (
    <div className="h-full flex flex-col relative">
      {processing && (
        <div className="absolute w-full h-full overflow-y-scroll py-4 bg-white z-50 flex justify-center items-center">
          <Loading text={['Vent litt', 'Tenker', 'Grubler', 'Prosesserer', 'Hmmm...', '2 sekund'][Math.floor(Math.random() * 5)]} />
        </div>
      )}

      <div className="flex font-medium">
        {ensureTextBlocks(blocks) && (
          <div
            className={`w-full text-center cursor-pointer border-t-8 border-profile-warmgray p-3 ${
              activeTab === 'tekst' ? 'bg-white border-profile-blue' : 'bg-profile-disabledgray'
            }`}
            onClick={() => setActiveTab('tekst')}
          >
            <p>Tekst</p>
          </div>
        )}
        <div
          className={`w-full text-center cursor-pointer border-t-8 border-profile-warmgray p-3 ${
            activeTab === 'farger' ? 'bg-white border-profile-blue' : 'bg-profile-disabledgray'
          }`}
          onClick={() => setActiveTab('farger')}
        >
          <p>Farger</p>
        </div>
        {ensureImageBlocks(blocks) && (
          <div
            className={`w-full text-center cursor-pointer border-t-8 border-profile-warmgray p-3 ${
              activeTab === 'bilde' ? 'bg-white border-profile-blue' : 'bg-profile-disabledgray'
            }`}
            onClick={() => setActiveTab('bilde')}
          >
            <p>Bilde / ikon</p>
          </div>
        )}
      </div>
      {activeTab === 'tekst' && (
        <div className="bg-white overflow-y-scroll py-4">
          {blocks.map((block, index) => (
            <React.Fragment key={`text-block-${block._key}`}>{createTextBlockFields(block, updateBlock(index))}</React.Fragment>
          ))}
        </div>
      )}
      {activeTab === 'farger' && (
        <div className="bg-white overflow-y-scroll py-4">
          <ColorPicker />
        </div>
      )}
      {activeTab === 'bilde' && (
        <div className="bg-white overflow-y-scroll py-4">
          <p className="text-md p-4">Last opp et bilde eller velg et ikon</p>
          {blocks.map((block, index) => (
            <React.Fragment key={`image-block-${block._key}`}>{createImageBlockFields(block, updateBlock(index))}</React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default EditorToolbar;
