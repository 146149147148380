import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const ProtectedRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { user, isAuthenticated } = useContext(UserContext);

  if (!Component) return null;

  return (
    <Route
      {...rest}
      render={props =>
        props.location.pathname.includes('preview') || (isAuthenticated && user) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { referrer: props.location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
