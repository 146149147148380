import MuseetSansBold from './convertFonts/MuseetSans-Bold';
import MuseetSansBoldItalic from './convertFonts/MuseetSans-BoldItalic';
import MuseetSansHeavy from './convertFonts/MuseetSans-Heavy';
import MuseetSansHeavyItalic from './convertFonts/MuseetSans-HeavyItalic';
import MuseetSansLight from './convertFonts/MuseetSans-Light';
import MuseetSansLightItalic from './convertFonts/MuseetSans-LightItalic';
import MuseetSansMedium from './convertFonts/MuseetSans-Medium';
import MuseetSansMediumItalic from './convertFonts/MuseetSans-MediumItalic';
import MuseetSansRegular from './convertFonts/MuseetSans-Regular';
import MuseetSansRegularItalic from './convertFonts/MuseetSans-RegularItalic';
import MuseetSansThin from './convertFonts/MuseetSans-Thin';
import MuseetSansThinItalic from './convertFonts/MuseetSans-ThinItalic';
import MuseetSerifBold from './convertFonts/MuseetSerif-Bold';
import MuseetSerifBoldItalic from './convertFonts/MuseetSerif-BoldItalic';
import MuseetSerifHeavy from './convertFonts/MuseetSerif-Heavy';
import MuseetSerifHeavyItalic from './convertFonts/MuseetSerif-HeavyItalic';
import MuseetSerifLight from './convertFonts/MuseetSerif-Light';
import MuseetSerifLightItalic from './convertFonts/MuseetSerif-LightItalic';
import MuseetSerifMedium from './convertFonts/MuseetSerif-Medium';
import MuseetSerifMediumItalic from './convertFonts/MuseetSerif-MediumItalic';
import MuseetSerifRegular from './convertFonts/MuseetSerif-Regular';
import MuseetSerifRegularItalic from './convertFonts/MuseetSerif-RegularItalic';

const fontMap = {
  MuseetSansBold: MuseetSansBold,
  MuseetSansBoldItalic: MuseetSansBoldItalic,
  MuseetSansHeavy: MuseetSansHeavy,
  MuseetSansHeavyItalic: MuseetSansHeavyItalic,
  MuseetSansLight: MuseetSansLight,
  MuseetSansLightItalic: MuseetSansLightItalic,
  MuseetSansMedium: MuseetSansMedium,
  MuseetSansMediumItalic: MuseetSansMediumItalic,
  MuseetSansRegular: MuseetSansRegular,
  MuseetSansRegularItalic: MuseetSansRegularItalic,
  MuseetSansThin: MuseetSansThin,
  MuseetSansThinItalic: MuseetSansThinItalic,
  MuseetSerifBold: MuseetSerifBold,
  MuseetSerifBoldItalic: MuseetSerifBoldItalic,
  MuseetSerifHeavy: MuseetSerifHeavy,
  MuseetSerifHeavyItalic: MuseetSerifHeavyItalic,
  MuseetSerifLight: MuseetSerifLight,
  MuseetSerifLightItalic: MuseetSerifLightItalic,
  MuseetSerifMedium: MuseetSerifMedium,
  MuseetSerifMediumItalic: MuseetSerifMediumItalic,
  MuseetSerifRegular: MuseetSerifRegular,
  MuseetSerifRegularItalic: MuseetSerifRegularItalic,
};

export default fontMap;
