import { customAlphabet } from 'nanoid';
import sanityClient from './client';
import { Document } from '../../types';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const nanoid = customAlphabet(alphabet, 21);

const createDocument = (userId: string, templateId: string, docTitle: string): Promise<Document> =>
  new Promise((resolve, reject) => {
    const doc = {
      _id: nanoid(), // can use sanity paths if we need to group these. By user perhaps?
      owner: {
        _type: 'reference',
        _ref: userId,
      },
      template: {
        _type: 'reference',
        _ref: templateId,
        _weak: true,
      },
      _type: 'output',
      title: docTitle,
    };
    sanityClient
      .create(doc)
      .then((document: Document) => resolve(document))
      .catch((error: Error) => reject(error));
  });

export default createDocument;
