import { Slider, withStyles } from '@material-ui/core';

const baseHeight = 18;
const multiplier = 1.75;

const DMSlider = withStyles({
  root: {
    color: '#D2E0E2',
    height: 8,
    // width: '50%',
  },
  thumb: {
    height: baseHeight * multiplier,
    width: baseHeight * multiplier,
    backgroundColor: '#3784A0',
    marginTop: baseHeight / -2 + (2 - multiplier),
    marginLeft: (baseHeight * multiplier) / -2,
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    color: 'red',
  },
  track: {
    display: 'none',
  },
  rail: {
    height: baseHeight,
    borderRadius: baseHeight / 2,
    marginLeft: baseHeight / -2,
    width: `calc(100% + ${baseHeight * 1.25}px)`,
  },
  mark: {
    width: Math.round(baseHeight / 4),
    height: Math.round(baseHeight / 4),
    borderRadius: baseHeight / 4,
    backgroundColor: '#3784A0',
    marginTop: Math.round(baseHeight / 2) - Math.round(baseHeight / 8),
  },
  markLabel: {
    color: '#3d3d3d',
    top: baseHeight * 2 + 8,
    fontWeight: 500,
    fontSize: 16,
    '&[data-index="0"]': {
      transform: `translateX(-20%)`, // dodgy stuff: ref https://github.com/mui-org/material-ui/issues/17023
    },
  },
  markLabelActive: {
    color: '#3784A0',
  },
  focusVisible: {
    outline: '5px auto blue !important',
  },
})(Slider);

export default DMSlider;
