import sanityClient from './client';
import { Category } from '../../types';

const getCategory = (slug:string): Promise<Category> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(`*[_type == 'category' && !(_id in path("drafts.**")) && slug.current == '${slug}' ][0]|{
        title,slug,description,examples
      }`)
      .then((result: Category) => resolve(result))
      .catch((error: Error) => reject(error));
  });

export default getCategory;
