import sanityClient from './client';
import { Document } from '../../types';

const fetchDocument = (documentId: string): Promise<Document> =>
  new Promise((resolve, reject) => {
    // console.log('graphQL Query: ', `*[_type == 'output' && _id == '${documentId}'][0]{..., 'template':template-> }`);
    sanityClient
      .fetch(`*[_type == 'output' && _id == '${documentId}'][0]{..., 'template':template-> }`)
      .then((document: Document) => resolve(document))
      .catch((error: Error) => reject(error));
  });

export default fetchDocument;
