import React, { useContext } from 'react';
import { EditorContext } from '../../EditorContext';
import { getColorStyle } from '../../../../utils/colorScheme';
import { Color } from '../../../../types';
import ParseColor from 'color';

const ColorPicker: React.FC = () => {
  const { colors, handleColorChange } = useContext(EditorContext);

  if (!colors) return null;

  // group all colors by id
  const groupedColors = colors.reduce((group, color) => {
    group[color._id] = group[color._id] || [];
    group[color._id].push(color as Color);
    return group;
  }, Object.create(null));

  // echo colors as buttons - one row per group
  const colorButtons = Object.keys(groupedColors).map(key => {
    return (
      <li key={key} className="flex justify-items-stretch">
        {groupedColors[key].map((color: Color) => {
          const style = getColorStyle(color);
          const { _id, shade, title } = color;

          return (
            <button
              className="flex-1 py-4 px-4 mr-3 mb-3"
              key={`${_id}.${shade}`}
              value={`${_id}.${shade}`}
              aria-label={`${title} - ${shade}`}
              title={`${title} - ${shade}`}
              onClick={handleColorChange}
              style={{ ...style, boxShadow: ParseColor(style.backgroundColor).luminosity() > 0.8 ? '0 0 0 1px inset #000000' : undefined }}
            >
              <p className="font-bold">Abc</p>
            </button>
          );
        })}
      </li>
    );
  });

  return (
    <section className="pt-4 pl-5 pr-2">
      <h2 className="text-lg mb-5 font-medium">Velg fargetema</h2>
      <ul>{colorButtons}</ul>
    </section>
  );
};

export default ColorPicker;
