import sanityClient from './client';
import { Color } from '../../types';

const fetchColors = (): Promise<Color[]> =>
  new Promise((resolve, reject) => {
    sanityClient
      .fetch(
        `*[_type == 'color']{
        _id,title,primary,secondary
      }`,
      )
      .then((colors: Color[]) => resolve(colors))
      .catch((error: Error) => reject(error));
  });

export default fetchColors;
